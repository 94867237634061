import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { apiRequest } from '../api';
import API_BASE_URL from '../apiConfig';
import audioManager from './AudioManager';
import './GameComponent.css'; // Import CSS for styling




const GameComponent = ({ balance, setBalance, setIsGameLoading, setFooterVisible }) => {
    const [message, setMessage] = useState('');
    const [showBetOptions, setShowBetOptions] = useState(false);
    const [showSearchingPopup, setShowSearchingPopup] = useState(false);
    const [gameMode, setGameMode] = useState('');
    const navigate = useNavigate();
    const { user, socket, updateUserDetails } = useContext(AuthContext);
    const [score, setScore] = useState(user ? user.score : 900); // Initialize score state from user context
    const [elapsedTime, setElapsedTime] = useState('00:00');
    const [isClosing, setIsClosing] = useState(false);
    const [gameStatus, setGameStatus] = useState(null);

 
    useEffect(() => {
      preloadSounds();
  }, []);
  

    const preloadSounds = () => {
      // Preload all necessary sounds
      audioManager.preloadSounds([
          { name: 'silent', path: '/sounds/silent.mp3' },
          { name: 'playerCardPlay', path: '/sounds/card_play.mp3' },
          { name: 'opponentCardPlay', path: '/sounds/card_play.mp3' },
          { name: 'playerDealCard', path: '/sounds/deal_card.mp3' },
          { name: 'playerDealRem_card', path: '/sounds/dealRem_card.mp3' },
          { name: 'opponentDealCard', path: '/sounds/deal_card.mp3' },
          { name: 'opponentDealRem_card', path: '/sounds/dealRem_card.mp3' },
          { name: 'faceCardSound', path: '/sounds/faceCard.mp3' },
          { name: 'gameStart', path: '/sounds/game_start.mp3' },
          { name: 'buttonClick', path: '/sounds/buttonClick.mp3' },
          { name: 'lose', path: '/sounds/lose.mp3' },
          { name: 'win', path: '/sounds/win.mp3' },
      ]);
  };
  
  const getAssetPaths = (gameMode) => {
      const commonAssets = [
          '/Tbackground.svg',
          '/cards/card_back.png',
          '/icons/avatar1.svg',
          '/icons/avatar2.svg',
          '/icons/hearts.svg',
          '/icons/diamonds.svg',
          '/icons/clubs.svg',
          '/icons/spades.svg',
          '/icons/anxoz.svg',
      ];
  
      const blotAssets = [
          '/cards/7_of_hearts.png', '/cards/7_of_diamonds.png', '/cards/7_of_clubs.png', '/cards/7_of_spades.png',
          '/cards/8_of_hearts.png', '/cards/8_of_diamonds.png', '/cards/8_of_clubs.png', '/cards/8_of_spades.png',
          '/cards/9_of_hearts.png', '/cards/9_of_diamonds.png', '/cards/9_of_clubs.png', '/cards/9_of_spades.png',
          '/cards/10_of_hearts.png', '/cards/10_of_diamonds.png', '/cards/10_of_clubs.png', '/cards/10_of_spades.png',
          '/cards/J_of_hearts.png', '/cards/J_of_diamonds.png', '/cards/J_of_clubs.png', '/cards/J_of_spades.png',
          '/cards/Q_of_hearts.png', '/cards/Q_of_diamonds.png', '/cards/Q_of_clubs.png', '/cards/Q_of_spades.png',
          '/cards/K_of_hearts.png', '/cards/K_of_diamonds.png', '/cards/K_of_clubs.png', '/cards/K_of_spades.png',
          '/cards/A_of_hearts.png', '/cards/A_of_diamonds.png', '/cards/A_of_clubs.png', '/cards/A_of_spades.png',
      ];
  
      const rpsAssets = [
          '/rpsAssets/rock.png',
          '/rpsAssets/paper.png',
          '/rpsAssets/scissors.png',  
      ];
  
      switch (gameMode) {
          case 'blot':
              return [...commonAssets, ...blotAssets];
          case 'rps':
              return [...commonAssets, ...rpsAssets];
          default:
              return commonAssets;
      }
  };
  
  const preloadAssets = async (gameMode) => {
      const assetPaths = getAssetPaths(gameMode);
  
      const loadAsset = (path) => {
          return new Promise((resolve) => {
              const img = new Image();
              img.src = path;
              img.onload = resolve;
              img.onerror = () => {
                  console.warn(`Failed to load asset: ${path}`);
                  resolve(); // Resolve even if the image fails
              };
          });
      };
  
      const loadAssets = Promise.all(assetPaths.map(loadAsset));
      const timeout = new Promise((resolve) => setTimeout(resolve, 3000)); // Resolve after 3 seconds regardless
  
      try {
          await Promise.race([loadAssets, timeout]);
      } catch (error) {
          console.error('Unexpected error during asset preloading:', error);
      }
  };
  

    const vibrate = (pattern) => {
      if ('vibrate' in navigator) {
          navigator.vibrate(pattern);
      }
  };




    const handleClosePopup = () => {
      setIsClosing(true);
      setFooterVisible(true);
      setTimeout(() => {
          setShowBetOptions(false);
          setIsClosing(false);
          setGameMode('');
           
      }, 200); // The duration should match the slideDown animation time (0.3s)
  };



useEffect(() => {
  if (showSearchingPopup) {
    setElapsedTime('00:00'); // Immediately set to 00:00 when the popup opens
    let seconds = 0;

    
    const interval = setInterval(() => {
      seconds += 1;
      const minutes = Math.floor(seconds / 60);
      const displaySeconds = seconds % 60;
      setElapsedTime(
        `${String(minutes).padStart(2, '0')}:${String(displaySeconds).padStart(2, '0')}`
      );
    }, 1000);

    return () => clearInterval(interval); // Cleanup the interval
  }
}, [showSearchingPopup]);



useEffect(() => {
    if (!user) {
        navigate('/login');
        return;
    }

    setScore(user.score); 
  
    

      
    const handleGameStart = async (data) => {
      
      if (data.game.status === 'in-progress') {
          setShowSearchingPopup(false);
          setGameStatus('in-progress');
          setIsGameLoading(true);
          try {
     
            await preloadAssets(data.game.gameMode);
            setIsGameLoading(false);
            navigate(`/${data.game.gameMode}`);
                      
          } catch (error) {
              console.error("Failed to preload images", error);
              setIsGameLoading(false);
          }
      }
     
  };



    const handlePlayerReconnected = ({ userId, gameMode }) => {
        if (userId === user._id) {
          navigate(`/${gameMode}`);
        }
    };

    const handleSearchTimeout = (data) => {

        setMessage(data.message);
        setBalance(data.balance);
        setShowSearchingPopup(false);
        setGameMode('');
    };

    // Add the necessary socket listeners
    socket.on('gameStarted', handleGameStart);
    socket.on('playerReconnected', handlePlayerReconnected);
    socket.on('search-timeout', handleSearchTimeout);



    return () => {

        socket.off('gameStarted', handleGameStart);
        socket.off('playerReconnected', handlePlayerReconnected);
        socket.off('search-timeout', handleSearchTimeout);
    };
}, [socket, user, setIsGameLoading,updateUserDetails]);


useEffect(() => {
  if (!showSearchingPopup && gameStatus === 'in-progress') {
     vibrate(400);
  }
}, [showSearchingPopup, gameStatus, navigate]);

    
    const startGame = async (amount, gameMode) => {
        if (!gameMode || amount == null) {
        setMessage('Please select a game and bet amount');
        return;
        }

        if (balance < amount) {
            setMessage('Insufficient balance');
            return;
        }

        if ((gameMode === 'blot' && (amount === 10 || amount === 20)) && user.score < 1000) {
            setMessage('Requires score over 1000');
            return;
        }

        if ((gameMode === 'rps' && (amount === 3 || amount === 5)) && user.score < 1000) {
        setMessage('Requires score over 1000');
        return;
        }

        const socketId = socket.id;

        try {
     

            const response = await apiRequest(`${API_BASE_URL}/api/start-game` , {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ betAmount: amount, gameMode, socketId })
            });

            const data = await response.json();

            if (response.ok) {
                setMessage('Searching for opponent...');
                if (amount > 0) {
                    setBalance(balance - amount);
                }
                setShowBetOptions(false);
                setShowSearchingPopup(true);
                setFooterVisible(true); 
            } else {
                setMessage(data.message || 'Failed to start game');
            }
        } catch (err) {
            setMessage('An error occurred');
            console.error('Error starting game:', err);
        }
    };

    const handleCancelSearch = async () => {

        try {
            const response = await apiRequest(`${API_BASE_URL}/api/cancel-search` , {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const data = await response.json();

            if (response.ok) {
               // setMessage('Search canceled');
                setBalance(data.balance);
                setGameMode('');
                setShowSearchingPopup(false);
            } else {
                setMessage(data.message || 'Failed to cancel search');
                setShowSearchingPopup(false);
            }
        } catch (err) {
            setShowSearchingPopup(false);
            setMessage('An error occurred');
            console.error('Error cancelling search:', err);
            
        }
    };

    const handleScoreClick = () => {
        navigate('/profile'); // Navigate to the score history page
    };

    const handleStartGameClick = (gameMode) => {
        audioManager.playSound('silent');
        setGameMode(gameMode);
        setShowBetOptions(true);
        setFooterVisible(false); 
        setMessage('');
    };

    const doNothing = () => {
  
      setMessage('');
  };

    const handleWithdrawClick = () => {
        navigate('/withdraw');
    };

    const handleHistoryClick = () => {
        navigate('/transaction-history');
    };
    
    const handleDepositClick = () => {
        navigate('/deposit');
    };



  
    
    return (
      <div className="game-component">
      {/* Show loading screen overlay if isGameLoading is true */}
      
          
           <div className="user-info">
  <div className="left-section">
    <button className="avatar-button" onClick={() => handleScoreClick()}>
      <div className="avatar">
        <img src="/home/avatar-icon.svg" alt="User Avatar" />
      </div>
    </button>
    <div className="user-details" onClick={() => handleScoreClick()}>
                    <div className="username-with-flag">
                        <span className="username">{user?.nickname}</span>
                        {user?.country && user.country !== '' && (
                            <img
                                src={`/flags/${user.country.toLowerCase()}.svg`}
                                alt={`${user.country} flag`}
                                className="flag-icon-user-details"
                            />
                        )}
                    </div>
                </div>
  </div>
  <div className="score-container">
    <div className="score-icon">
        <img src="/icons/score-icon.svg" alt="Score Icon" className="score-icon-img" />
    </div>
    <span className="score-value">{score}</span>
</div>
</div>



<div className="balance-container">
  <div className="balance-label">Total balance (USDT)</div>
  <div className="balance-display">
    <span className="currency-sign">$</span>
    <span className="main-balance">{balance != null && !isNaN(balance) ? Math.floor(balance).toLocaleString() : "0"}</span>
    <span className="decimal-point">.</span>
    <span className="decimal-balance">
      {balance !== undefined && balance !== null ? (Math.floor(balance * 100) / 100).toFixed(2).split('.')[1] : '00'}
    </span>
  </div>


<div className="button-container">
  <button className="action-button" onClick={handleDepositClick}>Deposit</button>
  <button className="action-button" onClick={handleWithdrawClick}>Withdraw</button>
  <button className="action-button" onClick={handleHistoryClick}>History</button>
</div>
</div>


 
<div className="games-section">
    <div className="game-item" onClick={() => handleStartGameClick('rps')}>
        <img src="/home/RPS-logo.png" alt="Rock Paper Scissors" className="game-logo" />
        <span className="game-title">Rock Paper Scissors</span>
        <p className="game-description">Quick and fun game to bet and win</p>
    </div>
    <div className="game-item" onClick={() => handleStartGameClick('blot')}>
        <img src="/home/Blot-logo.png" alt="Blot 1v1" className="game-logo" />
        <span className="game-title">Belote</span>
        <p className="game-description">Bet $ and win or play free game for fun</p>
    </div>
    <div className="game-item" onClick={() => handleStartGameClick('carrom')}>
        <img src="/home/Carrom-logo.png" alt="Carrom" className="game-logo" />
        <span className="game-title">Carrom</span>
        <p className="game-description">Strike your way to victory in this classic game!</p>
    </div>
    <div className="game-item disabled" onClick={() => doNothing()}>
        <img src="/home/backgammon-logo.png" alt="backgammon" className="game-logo" />
        <span className="game-title">Backgammon</span>
        <p className="game-description">Bet $ and win or play free game for fun</p>
    </div>

      
</div>


           {/* Bet Options Popup */}
{showBetOptions && (
    <div className="bet-popup-overlay" onClick={handleClosePopup}>
                    <div className={`bet-popup-container ${isClosing ? 'closing' : ''}`} onClick={(e) => e.stopPropagation()}>
      <div className="bet-popup">
        <div className="popup-header">
  <h3>Bet Options</h3>
  <button className="close-popup" onClick={handleClosePopup}>&times;</button>


</div>

   {/* Show error message inside the popup if any */}
   {message && (
                <div className="error-message">
                    <p>{message}</p>
                </div>
            )}


<div className="bet-options">
  {gameMode === 'blot' ? (
    <>
      <div className="bet-option" onClick={() => startGame(0, gameMode)}>
        <img src="/home/smile.svg" alt="Free Icon" className="option-icon" />
        <p>Free Game</p>
        <p className="bet-type">Play for Fun</p>
      </div>
      
      <div className="bet-option" onClick={() => startGame(5, gameMode)}>
        <img src="/home/coins.svg" alt="Standard Bet Icon" className="option-icon" />
        <p>$ Bet 5 - Win 9</p>
        <p className="bet-type">Standard Bet</p>
      </div>
      
      <div className="bet-option pro-game" onClick={() => startGame(10, gameMode)}>
        <img src="/home/medal.svg" alt="Pro Game Icon" className="option-icon" />
        <p>$ Bet 10 - Win 18</p>
        <p className="bet-type">Pro Game</p>
        <p className="bet-condition">Requires 1000+ Score</p>
      </div>
      
      <div className="bet-option pro-game" onClick={() => startGame(20, gameMode)}>
        <img src="/home/trophy.svg" alt="Pro Game Icon" className="option-icon" />
        <p>$ Bet 20 - Win 36</p>
        <p className="bet-type">Expert</p>
        <p className="bet-condition">Requires 1000+ Score</p>
      </div>
    </>
  ) : (
    <>
      <div className="bet-option" onClick={() => startGame(0, gameMode)}>
        <img src="/home/smile.svg" alt="Free Icon" className="option-icon" />
        <p>Free Game</p>
        <p className="bet-type">Play for Fun</p>
      </div>
      
      <div className="bet-option" onClick={() => startGame(1, gameMode)}>
        <img src="/home/coins.svg" alt="Standard Bet Icon" className="option-icon" />
        <p>$ Bet 1 - Win 1.8</p>
        <p className="bet-type">Standard Bet</p>
      </div>
      
      <div className="bet-option pro-game" onClick={() => startGame(3, gameMode)}>
        <img src="/home/medal.svg" alt="Pro Game Icon" className="option-icon" />
        <p>$ Bet 3 - Win 5</p>
        <p className="bet-type">Pro Game</p>
        <p className="bet-condition">Requires 1000+ Score</p>
      </div>
      
      <div className="bet-option pro-game" onClick={() => startGame(5, gameMode)}>
        <img src="/home/trophy.svg" alt="Pro Game Icon" className="option-icon" />
        <p>$ Bet 5 - Win 9</p>
        <p className="bet-type">Expert</p>
        <p className="bet-condition">Requires 1000+ Score</p>
      </div>
    </>
  )}
</div>



        </div>
    </div>
    </div>
)}

{/* Searching for Opponent Popup */}
{showSearchingPopup && (
  <div className="search-popup-overlay" onClick={handleCancelSearch}>
    <div className="search-popup" onClick={(e) => e.stopPropagation()}>
      <div className="search-popup-header">
        <h3>search</h3>
        <button className="close-popup" onClick={handleCancelSearch}>&times;</button>
      </div>
      <div className="popup-body">
        <p>
          Finding an opponent... <span>{elapsedTime}</span>
        </p>
        <div className="loader"></div>
        <button className="cancel-search-button" onClick={handleCancelSearch}>
          Cancel Search
        </button>
      </div>
    </div>
  </div>
)}

    
           
        </div>
    );
    
    
};

export default GameComponent;
