import React, { useEffect, useState, useRef } from 'react';
import { io } from 'socket.io-client';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import './AdminDashboard.css';

const AdminDashboard = () => {
    const [queueData, setQueueData] = useState({
        blot: {
            free: 0,
            five: 0,
            ten: 0,
            twenty: 0,
        },
        rps: {
            free: 0,
            five: 0,
            ten: 0,
            twenty: 0,
        },
        carrom: {
            free: 0,
            five: 0,
            ten: 0,
            twenty: 0,
        },
    });
    const [ongoingGames, setOngoingGames] = useState([]);
    const [completedGames, setCompletedGames] = useState([]);
    const [totalBets, setTotalBets] = useState(0);
    const [userCount, setUserCount] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const navigate = useNavigate();

    // Ref to the audio element
    const audioRef = useRef(null);

    const fetchData = async () => {
        const headers = { 'Content-Type': 'application/json' };

        // Armenia Time Offset (UTC+4)
        const armOffset = 4 * 60 * 60 * 1000;

        // Calculate Armenia start date
        const startDateArmenia = new Date(startDate);
        startDateArmenia.setHours(0, 0, 0, 0);
        const startDateUTC = new Date(startDateArmenia.getTime() + armOffset);

        // Calculate Armenia end date
        const endDateArmenia = new Date(endDate);
        endDateArmenia.setHours(23, 59, 59, 999);
        const endDateUTC = new Date(endDateArmenia.getTime() + armOffset);

        const resOngoing = await fetch('/api/admin/ongoing-games', {
            method: 'GET',
            headers,
            credentials: 'include',
        });
        setOngoingGames(await resOngoing.json());

        const resCompleted = await fetch(
            `/api/admin/completed-games?startDate=${startDateUTC.toISOString()}&endDate=${endDateUTC.toISOString()}`,
            {
                method: 'GET',
                headers,
                credentials: 'include',
            }
        );
        setCompletedGames(await resCompleted.json());

        const resBets = await fetch(
            `/api/admin/total-bets?startDate=${startDateUTC.toISOString()}&endDate=${endDateUTC.toISOString()}`,
            {
                method: 'GET',
                headers,
                credentials: 'include',
            }
        );
        setTotalBets((await resBets.json()).totalBets);

        const resUsers = await fetch('/api/admin/users?page=1&limit=1', {
            method: 'GET',
            headers,
            credentials: 'include',
        });
        const userData = await resUsers.json();
        setUserCount(userData.totalUsers);
    };

    useEffect(() => {
        fetchData();
    }, [startDate, endDate]);

    useEffect(() => {
        const socket = io('https://bets24.biz');

        socket.on('queueUpdate', (data) => {
            const updatedQueueData = {
                blot: {
                    free: 0,
                    five: 0,
                    ten: 0,
                    twenty: 0,
                },
                rps: {
                    free: 0,
                    five: 0,
                    ten: 0,
                    twenty: 0,
                },
                carrom: {
                    free: 0,
                    five: 0,
                    ten: 0,
                    twenty: 0,
                },
            };

            data.forEach((queue) => {
                if (queue.gameMode === 'blot') {
                    if (queue.betAmount === 0) updatedQueueData.blot.free = queue.count;
                    if (queue.betAmount === 5) updatedQueueData.blot.five = queue.count;
                    if (queue.betAmount === 10) updatedQueueData.blot.ten = queue.count;
                    if (queue.betAmount === 20) updatedQueueData.blot.twenty = queue.count;
                }
                if (queue.gameMode === 'rps') {
                    if (queue.betAmount === 0) updatedQueueData.rps.free = queue.count;
                    if (queue.betAmount === 1) updatedQueueData.rps.five = queue.count;
                    if (queue.betAmount === 3) updatedQueueData.rps.ten = queue.count;
                    if (queue.betAmount === 5) updatedQueueData.rps.twenty = queue.count;
                }
                if (queue.gameMode === 'carrom') {
                    if (queue.betAmount === 0) updatedQueueData.carrom.free = queue.count;
                    if (queue.betAmount === 1) updatedQueueData.carrom.five = queue.count;
                    if (queue.betAmount === 3) updatedQueueData.carrom.ten = queue.count;
                    if (queue.betAmount === 5) updatedQueueData.carrom.twenty = queue.count;
                }
            });

            setQueueData(updatedQueueData);
        });

        socket.on('ongoingGamesUpdate', (data) => {
            setOngoingGames(data);
        });

        return () => socket.disconnect();
    }, []);

    // Play sound when queueData changes
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.play().catch((error) => {
                console.error('Error playing sound:', error);
            });
        }
    }, [queueData]);

    return (
        <div className="admin-dashboard-container">
            {/* Audio element for sound */}
            <audio ref={audioRef} src="/sounds/game_start.mp3" preload="auto" />

            <h1 className="dashboard-title">Admin Dashboard</h1>

            {/* Date Range Picker */}
            <div className="date-range-picker">
                <h2>Select Date Range</h2>
                <div className="date-picker-group">
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy-MM-dd"
                        className="date-picker"
                    />
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="yyyy-MM-dd"
                        className="date-picker"
                    />
                </div>
            </div>

            <div className="dashboard-grid">
                {/* Queue Data */}
                <div className="dashboard-card">
                    <h2>Blot Queue</h2>
                    <ul>
                        <li className={queueData.blot.free !== 0 ? 'highlightQ' : ''}>
                            Free: <span>{queueData.blot.free}</span> players
                        </li>
                        <li className={queueData.blot.five !== 0 ? 'highlightQ' : ''}>
                            $5: <span>{queueData.blot.five}</span> players
                        </li>
                        <li className={queueData.blot.ten !== 0 ? 'highlightQ' : ''}>
                            $10: <span>{queueData.blot.ten}</span> players
                        </li>
                        <li className={queueData.blot.twenty !== 0 ? 'highlightQ' : ''}>
                            $20: <span>{queueData.blot.twenty}</span> players
                        </li>
                    </ul>
                </div>

                <div className="dashboard-card">
                    <h2>RPS Queue</h2>
                    <ul>
                        <li className={queueData.rps.free !== 0 ? 'highlightQ' : ''}>
                            Free: <span>{queueData.rps.free}</span> players
                        </li>
                        <li className={queueData.rps.five !== 0 ? 'highlightQ' : ''}>
                            $1: <span>{queueData.rps.five}</span> players
                        </li>
                        <li className={queueData.rps.ten !== 0 ? 'highlightQ' : ''}>
                            $3: <span>{queueData.rps.ten}</span> players
                        </li>
                        <li className={queueData.rps.twenty !== 0 ? 'highlightQ' : ''}>
                            $5: <span>{queueData.rps.twenty}</span> players
                        </li>
                    </ul>
                </div>

                <div className="dashboard-card">
                    <h2>Carrom Queue</h2>
                    <ul>
                        <li className={queueData.carrom.free !== 0 ? 'highlightQ' : ''}>
                            Free: <span>{queueData.carrom.free}</span> players
                        </li>
                        <li className={queueData.carrom.five !== 0 ? 'highlightQ' : ''}>
                            $1: <span>{queueData.carrom.five}</span> players
                        </li>
                        <li className={queueData.carrom.ten !== 0 ? 'highlightQ' : ''}>
                            $3: <span>{queueData.carrom.ten}</span> players
                        </li>
                        <li className={queueData.carrom.twenty !== 0 ? 'highlightQ' : ''}>
                            $5: <span>{queueData.carrom.twenty}</span> players
                        </li>
                    </ul>
                </div>

                {/* Ongoing Games */}
                <div className="dashboard-card">
                    <h2>Ongoing Games</h2>
                    <ul>
                        {ongoingGames.map((game) => (
                            <li key={game.id}>
                                
                                {game.gameMode === 'blot' 
                                 ? 'Blot' 
                                : game.gameMode === 'carrom' 
                                ? 'Carrom' 
                                : 'Rock-Paper-Scissors'} 
                                : {game.id} , Duration: {game.duration} seconds

                            </li>
                        ))}
                    </ul>
                </div>

                {/* Completed Games */}
                <div className="dashboard-card">
                    <h2>Completed Games</h2>
                    <p>{completedGames.totalGames} games completed</p>
                    <button
                        onClick={() => navigate('/admin/completed-games')}
                        className="view-all-button"
                        >
                            View All
                        </button>
                    </div>
    
                    {/* Total Bets */}
                    <div className="dashboard-card">
                        <h2>Total Bets</h2>
                        <p>Total Bet Amount: {totalBets}</p>
                    </div>
    
                    {/* Users */}
                    <div className="dashboard-card">
                        <h2>Users</h2>
                        <p>{userCount} total users</p>
                        <button
                            onClick={() => navigate('/admin/users')}
                            className="view-all-button"
                        >
                            View All
                        </button>
                    </div>
                </div>
            </div>
        );
    };
    
    export default AdminDashboard;
    
