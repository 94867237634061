import React, { useEffect, useState, useContext, useCallback,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { apiRequest } from '../api';
import './CarromGame.css';
import audioManager from './AudioManager';


const CarromGame = () => {
    const { socket, user } = useContext(AuthContext);

    // State variables
    const [renderTrigger, setRenderTrigger] = useState(false);
    const [game, setGame] = useState(null);
    const navigate = useNavigate();
    const [opponents, setOpponents] = useState([]);
    const [isGameEnd, setIsGameEnd] = useState(false);
    const [currentRound, setCurrentRound] = useState(null);
    const [popupMessage, setPopupMessage] = useState('');
    const [ShowCancelPopup, setShowCancelPopup] = useState(false);
    const STRIKER_SIZE = 30;
    const COIN_SIZE = 20;
    const STRIKER_RADIUS = STRIKER_SIZE / 2; 
    const COIN_RADIUS = COIN_SIZE / 2;
    const BOARD_WIDTH = 300;
    const BOARD_HEIGHT = 300; 
    const SENSITIVITY = 4;
    const FIXED_DELTA_TIME = 1 / 45; // Fixed time step in seconds (60 FPS)
    const MS_PER_FRAME = 1000 * FIXED_DELTA_TIME; // Convert to milliseconds 
    const collisionCooldownMs = 120; // Cooldown period in milliseconds
    const coinCollisionCooldownMs = 60; // Cooldown period in milliseconds
    const minVelocityThreshold = 0.2; // Define a threshold for significant velocity

    const [coins, setCoins] = useState([]);
    const [strikerPosition, setStrikerPosition] = useState({});
    const [isDragging, setIsDragging] = useState(false); // Dragging state
    const [handlePosition, setHandlePosition] = useState(100); // Horizontal position of the handle in the controller
   


    const controllerWidth = 200; // Width of the controller
    const handleWidth = 30; // Width of the handle
    const boardMinX = 65; // Minimum striker X on the board
    const boardMaxX = 235; // Maximum striker X on the board

    
    
    const [isTouchingStriker, setIsTouchingStriker] = useState(false);
    const [isControllerActive, setIsControllerActive] = useState(false);
    const [dragDots, setDragDots] = useState([]); // Dots array for striker interaction
    const [circleScale, setCircleScale] = useState(1); // Initial scale
    const [hitArrow, setHitArrow] = useState({ angle: 0, length: 0 });
    const [whiteDots, setWhiteDots] = useState([]);
    const [isFalling, setIsFalling] = useState(false);
    const [hitStrength, setHitStrength] = useState(0); // 0 to MAX_DISTANCE
    const [strikerVelocity, setStrikerVelocity] = useState({ x: 0, y: 0 }); // Striker velocity
    const hitStrengthRef = useRef(0);
    const hitArrowRef = useRef({ angle: 0, length: 0 });
    const coinsRef = useRef(coins);
    const strikerVelocityRef = useRef({ x: 0, y: 0 });
    const assignedPositions = useRef([]);

    const isFallingRef = useRef(false);
    const [isFoulPopupVisible, setIsFoulPopupVisible] = useState(false);
    const [isLastCoinPopupVisible, setIsLastCoinPopupVisible] = useState(false);
    const isClientReadyRef = useRef(false);
    const [timerStartTime, setTimerStartTime] = useState(null);
    


    useEffect(() => {
        if (!game || !timerStartTime || !user) return;
    
       
        
            const opponentId = game.userIds.find((id) => id !== user._id);
            const isPlayerTurn = game.turn === user._id && (game.phase === 'player-turn' || game.phase === 'opponent-turn');
            const isOpponentTurn = game.turn === opponentId && (game.phase === 'player-turn' || game.phase === 'opponent-turn');
    
            const playerTimerCircle = document.querySelector('.carrom-player-avatar .Carrom-timerP');
            const opponentTimerCircle = document.querySelector('.carrom-opponent-avatar .Carrom-timerO');
    
            // Helper function to calculate remaining time
            const calculateRemainingTime = () => {
                const elapsedTime = Date.now() - timerStartTime;
                return elapsedTime > 0 ? Math.max(0, 22000 - elapsedTime) : 20000;
            };

           
    
            // Handle Player Timer
            if (playerTimerCircle) {
                if (isPlayerTurn && !game.strikerBeenHit) {
                    const remainingTime = calculateRemainingTime();
                    console.log('Player remaining time:', remainingTime);
    
                    // Animate player timer
                    console.log('startingggggggggggg playeeeeeeeeeeeeeeeeer')
                    playerTimerCircle.style.transition = `stroke-dashoffset ${remainingTime / 1000}s linear`;
                    playerTimerCircle.style.strokeDashoffset = '0';
                   
                } else {
                    // Reset player timer
                    console.log('reseting playeeeeeeeeeeeeeeeeer')
                    
                    playerTimerCircle.style.strokeDashoffset = '188';
                    playerTimerCircle.style.transition = 'none';
                }
            }
    
            // Handle Opponent Timer
            if (opponentTimerCircle) {
                if (isOpponentTurn && !game.strikerBeenHit) {
                    const remainingTime = calculateRemainingTime();
                    console.log('Opponent remaining time:', remainingTime);
    
                    // Animate opponent timer
                    console.log('startingggggggggggg opponentttttttttttttt')
                    opponentTimerCircle.style.transition = `stroke-dashoffset ${remainingTime / 1000}s linear`;
                    opponentTimerCircle.style.strokeDashoffset = '0';
                   
                } else {
                    // Reset opponent timer
                    console.log('reseting opponenttttttttttttttttttt')
                    
                    opponentTimerCircle.style.strokeDashoffset = '188';
                    opponentTimerCircle.style.transition = 'none';
                }
            }
 
    

    }, [game?.turn, game?.strikerBeenHit]);
    
    
    
    


    const HOLES = [
        { x: 16, y: 16, radius: 16 },  // Top-left corner
        { x: 284, y: 16, radius: 16 }, // Top-right corner
        { x: 16, y: 284, radius: 16 }, // Bottom-left corner
        { x: 284, y: 284, radius: 16 } // Bottom-right corner
    ];
    

    
    
    const isFirstRender = useRef(true);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [isOverlapping, setIsOverlapping] = useState(false);
    const strikerPositionBeforeHitRef = useRef(null);

useEffect(() => {
    if (!game || !user || game.phase === "move-execution" || game.strikerBeenHit || isDragging) return;
    

    const whitePlayerID = Object.keys(game.playerColors).find(
        (id) => game.playerColors[id] === "white"
    );

    const newStrikerPosition =
        game.turn === whitePlayerID
            ? { x: 150, y: 257 } // Position for white player's turn
            : { x: 150, y: 43 }; // Position for black player's turn

    strikerPositionBeforeHitRef.current = newStrikerPosition;

    setStrikerPosition((prevPosition) => {
        
        // Skip animation on first render
        if (isFirstRender.current) {
            
            strikerVelocityRef.current = { x: 0, y: 0 };
            isFallingRef.current = false;
            setIsFalling(false);
            const isPlayerTurn = game?.turn === user?._id;
            setIsControllerActive(isPlayerTurn);
            isFirstRender.current = false;
            if (isOverlappingWithCoins(newStrikerPosition, coinsRef.current)) {
                const adjustedPosition = findNearestValidPosition(newStrikerPosition, coinsRef.current);
                if (isOverlapping) setIsOverlapping(false);
                strikerPositionBeforeHitRef.current = adjustedPosition;
                return adjustedPosition;
            }else {
                if (isOverlapping) setIsOverlapping(false);
                strikerPositionBeforeHitRef.current = newStrikerPosition;
                return newStrikerPosition; // Update the position state
            }
        }

        // Only animate if striker hasn't been hit, is not being dragged, and position changes
        if (
            !game.strikerBeenHit &&
            !isDragging &&
            (prevPosition.x !== newStrikerPosition.x ||
                prevPosition.y !== newStrikerPosition.y)
        ) {
            
            const strikerElement = document.querySelector(".carrom-striker");
            if (strikerElement) {
                setIsTransitioning(true);
                setIsFalling(false);
                
                
                strikerElement.style.transition = "top 0.6s ease, left 0.6s ease";
                strikerElement.style.top = `${newStrikerPosition.y}px`;
                strikerElement.style.left = `${newStrikerPosition.x}px`;

                // Clear transition styles after animation ends
                strikerElement.addEventListener(
                    "transitionend",
                    () => {
                        strikerElement.style.transition = "";
                        setIsTransitioning(false);
                        

                    },
                    { once: true }
                );
            } else {
                
                
            }
        }
        if (isOverlappingWithCoins(newStrikerPosition, coinsRef.current)) {
            const adjustedPosition = findNearestValidPosition(newStrikerPosition, coinsRef.current);
            if (isOverlapping) setIsOverlapping(false);
            strikerPositionBeforeHitRef.current = adjustedPosition;
            return adjustedPosition;
        }else {
            if (isOverlapping) setIsOverlapping(false);
            strikerPositionBeforeHitRef.current = newStrikerPosition;
            return newStrikerPosition; // Update the position state
        };
        
    });
}, [game, user]);


const isOverlappingWithCoins = (strikerPosition, coins = coinsRef.current) => {
    return coins.some((coin) => {
        const dx = strikerPosition.x - coin.x;
        const dy = strikerPosition.y - coin.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        return distance < STRIKER_RADIUS + COIN_RADIUS; // Adjust radius as needed
    });
};

const findNearestValidPosition = (
    position, 
    coins = coinsRef.current, 
    controllerWidth = 200
) => {
    const step = 5; // Small adjustment step
    const controllerMinX = boardMinX; // Minimum controller x-coordinate
    const controllerMaxX = boardMaxX; // Maximum controller x-coordinate
    const coinRadius = 10; // Example coin radius
    const strikerRadius = 15; // Example striker radius

    let leftPosition = { ...position }; // Start from the current position for left adjustment
    let rightPosition = { ...position }; // Start from the current position for right adjustment
    let leftTries = 0; // Counter for left-side adjustments
    let rightTries = 0; // Counter for right-side adjustments
    let maxTries = Math.ceil(controllerWidth / step); // Limit tries to controller width

    // Check if a position is valid (not overlapping with coins and within bounds)
    const isValidPosition = (pos) =>
        !coins.some((coin) => {
            const dx = pos.x - coin.x;
            const dy = pos.y - coin.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            return distance < coinRadius + strikerRadius;
        }) &&
        pos.x >= controllerMinX &&
        pos.x <= controllerMaxX;

    // Find the nearest clear position to the left
    while (leftTries < maxTries) {
        if (isValidPosition(leftPosition)) break;
        leftPosition.x -= step; // Shift left
        leftTries++;
    }

    // Find the nearest clear position to the right
    while (rightTries < maxTries) {
        if (isValidPosition(rightPosition)) break;
        rightPosition.x += step; // Shift right
        rightTries++;
    }

    // Decide which side required fewer adjustments and return that position
    if (leftTries <= rightTries && leftTries < maxTries) {
        return leftPosition; // Left side is closer and valid
    } else if (rightTries < maxTries) {
        return rightPosition; // Right side is closer and valid
    } else {
        console.error("Unable to find a valid position for the striker!");
        return position; // Return original position as a fallback
    }
};



    
     

    // Fetch game state from API
    const fetchGameState = useCallback(async () => {
        try {
            const response = await apiRequest('/api/current-game', {
                method: 'GET',
                credentials: 'include',
            });
            const data = await response.json();
            console.log('data on game fetch', data)
            if (response.ok && data.game && data.game.phase !== "move-execution") {
                if (data.game.status === 'completed') {
                    console.log('failed on fetch game')
                    navigate('/game');
                    return;
                }
                if (!timerStartTime) setTimerStartTime(Date.now());
                
                isClientSyncedRef.current = true;
                isFallingRef.current = false;
                setIsFalling(false);
                isFirstRender.current = true;
                
                setOpponents(data.opponents || []);
                if(data.game.phase === "opponent-turn"){
                isClientReadyRef.current = true;
                }
            
                handleGamePhase(data.game);
            } else if (response.ok && data.game && data.game.phase === "move-execution") {
                setGame(null);
                isClientSyncedRef.current = true;
                isFallingRef.current = false;
                setIsFalling(false);
                isFirstRender.current = true;
                setIsFoulPopupVisible(false);
                setIsLastCoinPopupVisible(false);
                 // Stop all coin movements by setting velocities to 0
                coinsRef.current.forEach((coin) => {
                coin.velocity.x = 0;
                coin.velocity.y = 0;
                });

                // Stop striker movement by setting its velocity to 0
                strikerVelocityRef.current.x = 0;
                strikerVelocityRef.current.y = 0;
               
                return;
            } else {
                setGame(null);
                navigate('/game');
                return;
            }
        } catch (error) {
            console.error('Error fetching game state:', error);
            setGame(null);
            navigate('/game');
            return;
        }
    }, [navigate]);

    // Handle game updates from socket
    useEffect(() => {
        if (!user) {
            navigate('/login');
            return;
        }

        socket.on('connect', () => {
            fetchGameState();
        });

        

        socket.on('game-update', (data) => {
            if (!data.game) {
                console.log('data.game missing on game update')
                navigate('/game');
                return;
            }
            
            console.log('data on game update', data)
            
            setOpponents(data.opponents || []);
            if (data.timeoutTriggered){
                setTimerExecuted(true);  
            };

            if (data.timerStartTime && data.game.phase !== 'move-execution') {
                setTimerStartTime(data.timerStartTime);
            };
            
            setIsDragging(false);
            handleGamePhase(data.game, data.timeoutTriggered);

        });

        return () => {
            socket.off('game-update');
            socket.off('connect');
            
        };
    }, [socket, user, navigate]);

 

    const dataGameSyncRef = useRef(null);
    const gameReadyRef = useRef(false);

   
    const animateStrikerToPosition = (targetPosition, game) => {
        return new Promise((resolve) => {

            const whitePlayerID = Object.keys(game.playerColors).find(
                (id) => game.playerColors[id] === "white"
            );
        
            const newStrikerPosition =
                game.turn === whitePlayerID
                    ? { x: 150, y: 257 } // Position for white player's turn
                    : { x: 150, y: 43 }; // Position for black player's turn
        
          

            const currentPos = strikerPositionBeforeHitRef.current || newStrikerPosition;

    
            // Define animation duration and easing function
            const duration = 300; // 300 ms for smooth transition
            const startTime = performance.now();
    
            const animate = (currentTime) => {
                const elapsedTime = currentTime - startTime;
                const progress = Math.min(elapsedTime / duration, 1);
    
                // Linear interpolation (can use easing functions for smoother animation)
                const newX = currentPos.x + (targetPosition.x - currentPos.x) * progress;
                const newY = currentPos.y + (targetPosition.y - currentPos.y) * progress;
    
                setStrikerPosition({ x: newX, y: newY });
    
                if (progress < 1) {
                    requestAnimationFrame(animate);
                } else {
                    resolve(); // Animation complete
                }
            };
    
            requestAnimationFrame(animate);
        });
    };
    

    // Handle game phases
    const handleGamePhase = (gameData, timerExecuted = false) => {
        const isPlayerTurn = gameData.turn === user._id;
        
        
    
        switch (gameData.phase) {
            case 'move-execution':
                // Player B animates the received move
                if (!isPlayerTurn) {
                    
                        checkAndExecuteMove(gameData);
                    
                }
                break;

                case 'opponent-turn': 
                        
                        dataGameSyncRef.current = gameData;
                        gameReadyRef.current = true;
                        
                        if (timerExecuted){
                            isClientReadyRef.current = true; 
                            console.log('ZZZZZZZZZZZZZZZZZZZZZZZ1111111111111111')

                        }
                       
                        
                       
                        setSyncGameOnUpdat((prev) => !prev);
                  
                
                    break;
                
                
                    case 'player-turn':
                        setIsControllerActive(isPlayerTurn);
                        setGame(gameData);
                        coinsRef.current = gameData.boardState.coins;
                        setCoins([...coinsRef.current]);
                        console.log('game started')
                        break;
    
            case 'game-canceled':
                // Handle game cancellation
                setShowCancelPopup(true);
                setTimeout(() => {
                    setShowCancelPopup(false);
                    navigate('/game');
                }, 4000);
                break;
    
            case 'game-completed':
                // Handle end of the game
                setGame(gameData);
                setIsGameEnd(true);
                break;
    
            default:
                console.warn(`Unhandled game phase: ${gameData.phase}`);
        }
    };

    const MAX_RETRIES_onExecute = 20;
    let retryCount_onExecute = 0;

    const checkAndExecuteMove = (gameData) => {
        if (isClientSyncedRef.current) {
            handleMoveExecution(gameData);
            
        } else if (retryCount_onExecute < MAX_RETRIES_onExecute) {
            retryCount_onExecute++;
            setTimeout(() => checkAndExecuteMove(gameData), 200); // Check again after 200ms
        } else {
            // Stop all coin movements by setting velocities to 0
            coinsRef.current.forEach((coin) => {
                coin.velocity.x = 0;
                coin.velocity.y = 0;
            });
    
            // Stop striker movement by setting its velocity to 0
            strikerVelocityRef.current.x = 0;
            strikerVelocityRef.current.y = 0;
    
            // Fetch game state after stopping the movements
        setTimeout(() => {
            isFallingRef.current = false;
            setIsFalling(false);
            fetchGameState();
        }, 1000); 
        }
    };

    const handleMoveExecution = (gameData) => {
        retryCount_onExecute = 0;
        setGame(gameData);
        isClientSyncedRef.current = false;
        const { lastMove } = gameData;
        endTurn();
        assignedPositions.current = [];

        const hitPosition = {x:lastMove.strikerPosition.x, y:lastMove.strikerPosition.y};
        animateStrikerToPosition(hitPosition, gameData).then(() => {
            strikerVelocityRef.current = {
                x: Math.cos(lastMove.angle) * lastMove.hitStrength / 10,
                y: Math.sin(lastMove.angle) * lastMove.hitStrength / 10,
            };
            setTimeout(() => {  
                startStrikerMovement(gameData); // Animate striker movement
            }, 1500);
            
        });
    }


    const [syncGameOnUpdat, setSyncGameOnUpdat] = useState(false); 
    const [syncGameOnReady, setSyncGameOnReady] = useState(false);
    const isClientSyncedRef = useRef(false);       
    const [timerExecuted, setTimerExecuted] = useState(false);     
    
 // Fetch initial game state
 useEffect(() => {
    if (!gameReadyRef.current || !isClientReadyRef.current || !dataGameSyncRef.current){
        console.log('XUYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY',gameReadyRef.current,isClientReadyRef.current,dataGameSyncRef.current)
        return;
    }

         // Stop all coin movements by setting velocities to 0
         coinsRef.current.forEach((coin) => {
            coin.velocity.x = 0;
            coin.velocity.y = 0;
        });

        // Stop striker movement by setting its velocity to 0
        strikerVelocityRef.current.x = 0;
        strikerVelocityRef.current.y = 0;
        
        const checkPlayerTurn = dataGameSyncRef.current.turn === user._id;


        console.log('Controller activated, game synced.',dataGameSyncRef.current);
        setGame(dataGameSyncRef.current);
        setIsControllerActive(checkPlayerTurn);
        coinsRef.current = dataGameSyncRef.current.boardState.coins;
        setCoins([...coinsRef.current]);

        
        isClientReadyRef.current = false; 
        gameReadyRef.current = false;
        dataGameSyncRef.current = null;
        isFallingRef.current = false;
        isClientSyncedRef.current = true;

        if(timerExecuted){
            setTimeout(() => {  
                setTimerExecuted(false);    
            }, 2200);  
        }
        
        
                       
}, [syncGameOnUpdat,syncGameOnReady]);


useEffect(() => {
    const handleVisibilityChange = () => {
        if (!document.hidden) {
            fetchGameState();
        }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
}, [fetchGameState]); 

    // Fetch initial game state
    useEffect(() => {
        fetchGameState();
    }, [fetchGameState]);


    const triggerConfettiAnimation = () => {
        const confettiContainer = document.createElement('div');
        confettiContainer.className = 'confetti-container';
    
        for (let i = 0; i < 200; i++) { 
            const confetti = document.createElement('div');
            confetti.className = 'confetti-piece';
            
          
            confetti.style.left = '50%';
            confetti.style.top = '50%'; 
            confetti.style.backgroundColor = `hsl(${Math.random() * 360}, 100%, 50%)`; 
            confetti.style.transform = `rotate(${Math.random() * 360}deg)`; 
    
      
            const randomX = (Math.random() - 0.5) * window.innerWidth * 2; 
            const randomY = (Math.random() - 0.5) * window.innerHeight * 2;
            const duration = Math.random() * 1.4 + 0.8; 
    
            confetti.style.animation = `explode ${duration}s ease-out forwards`;
            confetti.style.setProperty('--randomX', `${randomX}px`); 
            confetti.style.setProperty('--randomY', `${randomY}px`); 
    
            confettiContainer.appendChild(confetti);
        }
    
        document.body.appendChild(confettiContainer);
    
        setTimeout(() => {
            document.body.removeChild(confettiContainer);
        }, 4000);  
    };

    // Handle game end animations and cleanup
    useEffect(() => {
        if (game && user && isGameEnd) {
            if (game.winners?.includes(user._id)) {
                triggerConfettiAnimation();
                audioManager.playSound('win');
            } else {
                audioManager.playSound('lose');
            }

            const timeout = setTimeout(() => {
                navigate('/game');
            }, 5000);

            return () => clearTimeout(timeout);
        }
    }, [isGameEnd, game, user, navigate]);

    const renderGameEndPopup = () => {
        if (!isGameEnd || !game) return null;
    
        const winnersArray = game.winners || [];
        const losersArray = game.losers || [];
        const winnerId = winnersArray[0] || null;
        const loserId = losersArray[0] || null;
        const winnerPoints = game.totalGamePoints[winnerId] || 0;
        const loserPoints = game.totalGamePoints[loserId] || 0;
        const isUserWinner = winnerId === user._id;
  
    
        return (
            <div className="carrom-end-game-popup">
                <div className="carrom-end-game-header">
                    <h3 className={isUserWinner ? "carrom-win" : "carrom-lose"}>
                        {isUserWinner ? (
                            <>
                                <span className="carrom-end-game-emoji">🏆</span> You Won the Game!
                            </>
                        ) : winnerId ? (
                            <>
                                <span className="carrom-end-game-emoji">😞</span> {game.usernames[winnerId]} Won the Game
                            </>
                        ) : (
                            <>
                                <span className="carrom-end-game-emoji">🤝</span> The Game is a Tie!
                            </>
                        )}
                    </h3>
                </div>
    
                <div className="carrom-end-game-results-table">
                    <div className="carrom-end-game-results-row carrom-end-game-header">
                        <div>Game Result</div>
                    </div>
                    <div className="carrom-end-game-results-row carrom-end-game-score">
                        <div className={isUserWinner ? "carrom-end-game-winner-score" : "carrom-end-game-loser-score"}>
                            {isUserWinner ? winnerPoints : loserPoints}
                        </div>
                        <span className="carrom-end-game-colon">:</span>
                        <div className={isUserWinner ? "carrom-end-game-loser-score" : "carrom-end-game-winner-score"}>
                            {isUserWinner ? loserPoints : winnerPoints}
                        </div>
                    </div>
    
                    <div className="carrom-results-row carrom-score-row">
                        <div className="carrom-results-left">
                            <div className="carrom-score-icon">
                                <img src="/icons/score-icon.svg" alt="Score" />
                            </div>
                            <div className="carrom-current-score">{user.score}</div>
                        </div>
                        <div className={`carrom-score-result ${isUserWinner ? 'carrom-score-positive' : 'carrom-score-negative'}`}>
                            {isUserWinner ? "+10" : "-5"}
                        </div>
                    </div>
    
                    <div className="carrom-results-row carrom-bet-row">
                        <div className="carrom-results-left">Bet Amount</div>
                        <div className="carrom-results-right">
                            <span className="carrom-dollar-signBET">$</span>
                            <span className="carrom-bet-amount">{game.betAmount}</span>
                        </div>
                    </div>
    
                    {isUserWinner && game.betAmount > 0 && (
                        <div className="carrom-results-row carrom-winning-row">
                            <div className="carrom-results-left">Winnings</div>
                            <div className="carrom-results-right">
                                <span className="carrom-dollar-sign">$</span>
                                <span className="carrom-winning-amount">{calculateWinnerGain(game.betAmount)}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const calculateWinnerGain = (betAmount) => {
        switch (betAmount) {
            case 1:
                return 1.8;
            case 3:
                return 5;
            case 5:
                return 9;
            default:
                return 0; 
        }
    };

    

    const renderAvatar = (name, photoUrl) => {
        if (photoUrl) {
            return <img src={photoUrl} alt={`${name}'s Avatar`} className="carrom-avatar-image" />;
        }
        const initial = name.charAt(0).toUpperCase();
        return <div className="carrom-avatar-placeholder">{initial}</div>;
    };
    
    const renderOpponentArea = (game, user, opponents) => {
        const opponent = opponents.find(opponent => opponent._id !== user._id);
    
        if (!opponent) {
            return null; // No opponent found
        }
    
        const opponentColor = game.playerColors[opponent._id]; // Get opponent's assigned color
    
        return (
            <div className={`carrom-opponent-wrapper ${game.turn === opponent._id && !game.strikerBeenHit  ? 'highlighted waiting-for-move' : ''}`}>
                <div className="carrom-opponent-area">
                    {/* Opponent Avatar */}
                    <div className="carrom-opponent-avatar">
                        {renderAvatar(opponent.nickname, opponent.photoUrl)}
                        <svg className="Carrom-timer-circleV">
                <defs>
                    <linearGradient id="gradientStroke" x1="50%" y1="0%" x2="50%" y2="100%">
                        <stop offset="0%" style={{ stopColor: '#199519', stopOpacity: 1 }} />
                        <stop offset="100%" style={{ stopColor: '#19E127', stopOpacity: 1 }} />
                    </linearGradient>
                </defs>
                <circle className="Carrom-base" cx="50%" cy="50%" r="45%" />
                <circle className="Carrom-timerO" cx="50%" cy="50%" r="45%" stroke="url(#gradientStroke)" />
            </svg>
                    </div>
    
                    {/* Opponent Info */}
                    <div className="carrom-opponent-info">
                        <div className="carrom-name-and-flag">
                            <span className="carrom-opponent-name">{opponent.nickname}</span>
                            {opponent.country && (
                                <img
                                    src={`/flags/${opponent.country.toLowerCase()}.svg`}
                                    alt={`${opponent.country} flag`}
                                    className="carrom-country-flag"
                                />
                            )}
                        </div>
                        <span className="carrom-opponent-score">Score: {opponent.score}</span>
                        <span className="carrom-opponent-betAmmount">Bet: $ {game?.betAmount}</span>
                    </div>
    
                    <div className="carrom-opponent-coins">
                        {/* Render the red coin (queen) if pocketed */}
                        {game.pocketedPieces[opponent._id]?.some(coin => coin.type === 'queen') && (
                            <div
                                className="carrom-opponent-coin-icon"
                                style={{
                                    backgroundImage: `url(/carromAssets/queen-coin.png)`,
                                    opacity: game.queenState === "secured" ? 1 : 0.3,
                                }}
                            ></div>
                        )}
    
                        {/* Render the opponent's color coin */}
                        <div
                            className="carrom-opponent-coin-icon"
                            style={{
                                backgroundImage: `url(/carromAssets/${opponentColor}-coin.png)`,
                            }}
                        ></div>
                        <div className="carrom-opponent-coin-count">
                            {game.pocketedPieces[opponent._id]?.filter(coin => coin.type === opponentColor && coin.type !== 'queen').length || 0}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    


   

    // Calculate initial handle position in the controller
    useEffect(() => {
    if (!isControllerActive) return;
    const initialHandlePosition =
        ((strikerPosition.x - boardMinX) / (boardMaxX - boardMinX)) * (controllerWidth - handleWidth);
    setHandlePosition(initialHandlePosition);
    }, [strikerPosition.x]);

    const handleDragStart = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const strikerPositionOnDrugEndRef = useRef({});

    const handleDragMove = (event, game) => {
        if (!isDragging || !isControllerActive) return;

        // Check if the current player is the second player
        const isSecondPlayer = game?.playerColors[user._id] === "black";
    
        // Get event coordinates (for both mouse and touch)
        const clientX = event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
    
        // Get controller dimensions
        const controller = document.querySelector(".striker-controller");
        const controllerRect = controller.getBoundingClientRect();
        const controllerLeft = controllerRect.left;
    
        // Calculate relative position of the handle
        const relativeX = clientX - controllerLeft - handleWidth / 2; // Adjust for handle's center
        const constrainedX = Math.max(0, Math.min(controllerWidth - handleWidth, relativeX));

         // Adjust for second player's mirrored perspective
        const adjustedConstrainedX = isSecondPlayer
        ? controllerWidth - handleWidth - constrainedX
        : constrainedX;

    
        // Update handle position in the controller
        setHandlePosition(adjustedConstrainedX);
    
        // Map controller handle position to board striker position
        const mappedX =
            boardMinX + (adjustedConstrainedX / (controllerWidth - handleWidth)) * (boardMaxX - boardMinX);

        const newPosition = { x: mappedX, y: strikerPosition.y };

        const isOverlapping = isOverlappingWithCoins(newPosition, coinsRef.current);
        if (isOverlapping) {
            // Add red styling to the striker animation
            setIsOverlapping(true);
        } else {
            // Remove red styling
            setIsOverlapping(false);
        }
    
        strikerPositionOnDrugEndRef.current = newPosition
        // Update striker position on the board
        setStrikerPosition(newPosition);
    };


    
    const handleDragEnd = () => {
        let finalPosition = strikerPositionOnDrugEndRef.current;
        if (isOverlappingWithCoins(finalPosition, coinsRef.current)) {
            const adjustedPosition = findNearestValidPosition(strikerPositionOnDrugEndRef.current, coinsRef.current);
            console.log('Adjusted Position:', adjustedPosition);

            setStrikerPosition(adjustedPosition);
            setIsOverlapping(false);
            
            strikerPositionOnDrugEndRef.current = adjustedPosition;
            console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA Position:', adjustedPosition);
        }
        console.log('FFFFFFFFFFFFFFFFFFFFFFFinal Position:', finalPosition);
    
        setIsDragging(false);
    };
    
    










    
        
    const handleStrikerTouchStart = (event, game) => {
        
        event.preventDefault();
        if (strikerVelocity.x !== 0 || strikerVelocity.y !== 0 || game.turn != user._id || game.strikerBeenHit || timerExecuted) return; // Prevent interaction during motion
        setIsTouchingStriker(true);
        setDragDots([]);
        setIsControllerActive(false);
    };
    
    

    const MAX_DISTANCE = 60; // Define maximum distance for circle, arrow, and dots

    const handleStrikerTouchMove = (event, game) => {
        event.preventDefault();
        if (!isTouchingStriker) return;
    
        const board = document.querySelector(".carrom-board");
        const boardRect = board.getBoundingClientRect();
    
        const clientX = event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
        const clientY = event.type === "mousemove" ? event.clientY : event.touches[0].clientY;

          // Determine if the current player is the second player
        const isSecondPlayer = game?.playerColors[user._id] === "black";
    
        const relativeX = isSecondPlayer
        ? boardRect.right - clientX
        : clientX - boardRect.left;
        const relativeY = isSecondPlayer
        ? boardRect.bottom - clientY
        : clientY - boardRect.top;
    
        const dx = relativeX - strikerPosition.x;
        const dy = relativeY - strikerPosition.y;
        const distance = Math.min(Math.sqrt(dx * dx + dy * dy), MAX_DISTANCE);
    
        // Calculate strength and store it in the ref
        const calculatedStrength = distance < 18 ? 0 : (distance / MAX_DISTANCE) * 200;
        hitStrengthRef.current = calculatedStrength;

    
        const cappedDx = (dx / Math.sqrt(dx * dx + dy * dy)) * distance;
        const cappedDy = (dy / Math.sqrt(dx * dx + dy * dy)) * distance;
    
        const newDarkDots = [];
        const steps = Math.round(distance / 10);
        for (let i = 1; i <= steps; i++) {
            newDarkDots.push({
                x: strikerPosition.x + (cappedDx / steps) * i,
                y: strikerPosition.y + (cappedDy / steps) * i,
            });
        }
        setDragDots(newDarkDots);
    
        const newScale = distance / 11;
        setCircleScale(newScale);
    
        const arrowAngle = (Math.atan2(-cappedDy, -cappedDx) * (180 / Math.PI) + 360) % 360;
        const arrowLength = Math.min(newScale * 11, distance);
    
        setHitArrow({
            angle: arrowAngle,
            length: arrowLength,
        });
        hitArrowRef.current = { angle: arrowAngle, length: arrowLength };
    
        const maxWhiteDots = 5;
        const whiteDotsSpacing = 10;
        const whiteDots = [];
        for (let i = 1; i <= maxWhiteDots; i++) {
            const arrowX = strikerPosition.x + (-cappedDx / distance) * arrowLength;
            const arrowY = strikerPosition.y + (-cappedDy / distance) * arrowLength;
            whiteDots.push({
                x: arrowX + (-cappedDx / distance) * whiteDotsSpacing * i,
                y: arrowY + (-cappedDy / distance) * whiteDotsSpacing * i,
                opacity: 1 - i * 0.2,
            });
        }
        setWhiteDots(whiteDots);
    };
    
    
    
    
    
    
    const handleStrikerTouchEnd = async (game) => {
        const strength = hitStrengthRef.current; // Access the latest strength
        const { angle } = hitArrowRef.current; // Access the latest angle from the ref
        // Store previous state for rollback

      
    
        if (strength > 0 && !timerExecuted) {
            
           
           
            const angleInRadians = angle * (Math.PI / 180);
            const speed = strength / 10;
    
            const velocityX = Math.cos(angleInRadians) * speed;
            const velocityY = Math.sin(angleInRadians) * speed;
    
            
      
              // Prepare move data for API
            const moveData = {
            playerId: user._id,
            strikerPosition,
            angle: angleInRadians,
            speed,
            hitStrength: strength,
            };

            // Update the ref for smooth animations
            strikerVelocityRef.current = { x: velocityX, y: velocityY };
            
            endTurn();
            assignedPositions.current = [];
            game.strikerBeenHit = true;

            // Start movement locally for immediate feedback
            startStrikerMovement();
    
          

            setIsTouchingStriker(false);
            setDragDots([]);
            setCircleScale(1);
            setHitStrength(0);

          
        
    
            try {
                // Send move data to the server
                const response = await apiRequest('/api/make-carrom-move', {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ gameId: game._id, move: moveData }),
                });
                // If the response is not successful (e.g., 500 or 423), handle the error
                if (!response.ok) {
                    
                        // Stop all coin movements by setting velocities to 0
                        coinsRef.current.forEach((coin) => {
                            coin.velocity.x = 0;
                            coin.velocity.y = 0;
                        });
                
                        // Stop striker movement by setting its velocity to 0
                        strikerVelocityRef.current.x = 0;
                        strikerVelocityRef.current.y = 0;
                
                        // Fetch game state after stopping the movements
                    setTimeout(() => {
                        isFallingRef.current = false;
                        setIsFalling(false);
                        fetchGameState();
                    }, 3000); 
                    
                }
          
            }  catch (error) {
                console.error('Unexpected error during API call:', error);
                isFallingRef.current = false;
                setIsFalling(false);
                fetchGameState();
            }
        } else {
            setIsTouchingStriker(false);
            setDragDots([]);
            setIsControllerActive(true);
           
        }
        
    };
    
    


    const collisionTrackerRef = useRef(new Map());
    const coinCollisionTrackerRef = useRef(new Map());


const startStrikerMovement = (currentGame = game) => {

    
    let accumulatedTime = 0;
    let lastTimestamp = performance.now(); // Keep track of the last frame's timestamp
    
     // Reset the collision trackers
     collisionTrackerRef.current = new Map();
     coinCollisionTrackerRef.current = new Map();

     let totalSimulatedTime = 0;
    
     const pocketedCoins = [];
     
    const updatePosition = (timestamp) => {
        const delta = timestamp - lastTimestamp;

        totalSimulatedTime += delta;

        lastTimestamp = timestamp;
        accumulatedTime += delta;

        
        
        while (accumulatedTime >= MS_PER_FRAME) {
        accumulatedTime -= MS_PER_FRAME;
        if (!isFallingRef.current) {
            // Update striker position and velocity
        setStrikerPosition((prev) => {
            const velocity = strikerVelocityRef.current;

            let newX = prev.x + velocity.x ;
            let newY = prev.y + velocity.y ;

            let newVelocityX = velocity.x;
            let newVelocityY = velocity.y;

            // Wall collision logic for striker
            if (newX - STRIKER_RADIUS <= 0) {
                newX = STRIKER_RADIUS;
                newVelocityX = -newVelocityX * 0.8;
            }
            if (newX + STRIKER_RADIUS >= 300) {
                newX = 300 - STRIKER_RADIUS;
                newVelocityX = -newVelocityX * 0.8;
            }
            if (newY - STRIKER_RADIUS <= 0) {
                newY = STRIKER_RADIUS;
                newVelocityY = -newVelocityY * 0.8;
            }
            if (newY + STRIKER_RADIUS >= 300) {
                newY = 300 - STRIKER_RADIUS;
                newVelocityY = -newVelocityY * 0.8;
            }

            // Apply friction to the striker
            const friction = 0.975;
            newVelocityX *= friction;
            newVelocityY *= friction;

            // Stop if velocity is negligible
            if (Math.abs(newVelocityX) < 0.2) newVelocityX = 0;
            if (Math.abs(newVelocityY) < 0.2) newVelocityY = 0;

            // Update striker velocity reference
            strikerVelocityRef.current = { x: newVelocityX, y: newVelocityY };

            // Check for striker-coin collisions
            checkStrikerCoinCollision(newX, newY, collisionTrackerRef.current);

            // Check if striker is falling into a hole
            for (const hole of HOLES) {
                const dx = newX - hole.x;
                const dy = newY - hole.y;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance <= hole.radius + SENSITIVITY) {
                    handleStrikerFallIntoHole(hole);
                    
                    return { x: hole.x, y: hole.y }; // Place striker at the hole's center
                }
            }

            return { x: newX, y: newY };
        });
         } // Stop movement if the striker is falling into a hole

        
        
        // Resolve coin-to-coin collisions
        resolveCoinCollisions(coinCollisionTrackerRef.current);

        // Update coin positions and check for hole collisions
        coinsRef.current = coinsRef.current.map((coin) => {
            
            let newX = coin.x + coin.velocity.x ;
            let newY = coin.y + coin.velocity.y ;
        
            let newVelocityX = coin.velocity.x;
            let newVelocityY = coin.velocity.y;
        
            // Wall collisions for coins
            if (newX - COIN_RADIUS <= 0) {
                newX = COIN_RADIUS;
                newVelocityX *= -1;
            }
            if (newX + COIN_RADIUS >= 300) {
                newX = 300 - COIN_RADIUS;
                newVelocityX *= -1;
            }
            if (newY - COIN_RADIUS <= 0) {
                newY = COIN_RADIUS;
                newVelocityY *= -1;
            }
            if (newY + COIN_RADIUS >= 300) {
                newY = 300 - COIN_RADIUS;
                newVelocityY *= -1;
            }
        
            // Apply friction to coins
            const friction = 0.975;
            newVelocityX *= friction;
            newVelocityY *= friction;
        
            // Stop if velocity is negligible
            if (Math.abs(newVelocityX) < 0.2) newVelocityX = 0;
            if (Math.abs(newVelocityY) < 0.2) newVelocityY = 0;
        
            // Check if coin is falling into a hole
            for (const hole of HOLES) {
                const dx = newX - hole.x;
                const dy = newY - hole.y;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance <= hole.radius + SENSITIVITY) {
                    if (!coin.isProcessed) {
                        pocketedCoins.push({ coin, hole });
                        coin.velocity = { x: 0, y: 0 };
                        newVelocityX = 0;
                        newVelocityY = 0;
                        coin.x = hole.x;
                        coin.y = hole.y;
                        coin.isProcessed = true; // Mark as processed
                        console.log(`Coin ${coin.id} fell into the hole!`);

                        const coinElement = document.querySelector(`#coin-${coin.id}`);
                        if (coinElement) {
                            coinElement.style.transform = "translate(-50%, -50%) scale(0.7)"; // Combine translate and scale
                            coinElement.style.transition = "transform 0.8s ease"; // Smooth scaling transition
                        }
            
                        
                        return coin;
                    }
                }
            }

        
            // Update the coin's position and velocity
            return { ...coin, x: newX, y: newY, velocity: { x: newVelocityX, y: newVelocityY } };
            
        });
        
        
    }

        // Sync coins state to trigger re-render
        setCoins([...coinsRef.current.filter((coin) => !coin.isProcessed)]);

        // Continue animation if velocity is non-zero
        if (
            Math.abs(strikerVelocityRef.current.x) > 0 ||
            Math.abs(strikerVelocityRef.current.y) > 0 ||
            coinsRef.current.some(
                (coin) => Math.abs(coin.velocity.x) > 0 || Math.abs(coin.velocity.y) > 0
            )
        ) {
            requestAnimationFrame(updatePosition);
        }else {
           
            
                setTimeout(() => {
                    console.log('coins stopeeeeeeeeeeeeeeed checking handlePocketedCoins',pocketedCoins)
                    
                    handlePocketedCoins(pocketedCoins, currentGame);
                    
                    
                }, 600); // Match the delay with the scaling animation duration
            

            console.log(`Total simulated time in frontend: ${totalSimulatedTime} ms`);
           
    
        }
    };
    
    requestAnimationFrame(updatePosition);
   
};

    
    
const triggerCoinPocketAnimation = (game, coin, hole, destination, onComplete) => {
    return new Promise((resolve) => {
    const coinElement = document.querySelector(`#coin-${coin.id}`); // Find the DOM element for the coin
    const isSecondPlayer = game.playerColors[user._id] === "black";
    if (!coinElement) {
        console.log('Coin element not found for coin ID:');

        if (onComplete) onComplete(); // Call the callback to avoid blocking readiness
        setTimeout(() => {
            console.log("Coins element not found RESOLVING PROMISEEEEEEEEEEEE.");
            resolve(); // Resolve after the animation is complete
        }, 2000); // Example duration for animation
        return;
    }

    const board = document.querySelector(".carrom-board"); // Reference to the board
    const boardRect = board.getBoundingClientRect(); // Get board's absolute position
    

    // Adjust destination coordinates to be relative to the carrom-board
    const adjustedDestinationX = destination.left - boardRect.left + COIN_RADIUS;
    const adjustedDestinationY = destination.top - boardRect.top + COIN_RADIUS;

    // Center of the hole
    const holeCenterX = hole.x;
    const holeCenterY = hole.y;

    const dx = adjustedDestinationX - holeCenterX;
    const dy = adjustedDestinationY - holeCenterY;

    // Move the coin element to the destination
    coinElement.style.zIndex = "100"; // Bring the coin to the front
    coinElement.style.transform = "translate(-50%, -50%) scale(1)"; 
    coinElement.style.transition = "transform 0.6s ease, top 0.6s ease, left 0.6s ease";
    coinElement.style.top = `${holeCenterY + dy}px`;
    coinElement.style.left = `${holeCenterX + dx}px`;

    coinElement.addEventListener(
        "transitionend",
        () => {
            // Save the final position into the coin object
            if(!isSecondPlayer){
                lastPositionsRef.current.set(coin.type, { x: holeCenterX + dx, y: holeCenterY + dy });
                coin.x = holeCenterX + dx;
                coin.y = holeCenterY + dy;
            } else {
                lastPositionsRef.current.set(coin.type, { x: 300 - (holeCenterX + dx), y: 300 - (holeCenterY + dy) });
                coin.x = 300 - (holeCenterX + dx);
                coin.y = 300 - (holeCenterY + dy);
            }
            
            
    
            // Notify completion
            if (onComplete) onComplete();
        },
        { once: true }
    );
    setTimeout(() => {
        console.log("Coins pocket animation complite.");
        resolve(); // Resolve after the animation is complete
    }, 3000); // Example duration for animation
});
};



let isProcessingPocketedCoins = false; // Global flag to prevent duplicate calls
let accumulatedPocketedCoinsForRound = [];
let currentAnimationFrame = null;

const handlePocketedCoins = (pocketedCoins, game) => {
    if (!game){
        isClientReadyRef.current = true; 
        console.log('ZZZZZZZZZZZZZZZZZZZZZZZ1111111111111111')
        setSyncGameOnReady((prev) => !prev);
        return;
    }
    // Accumulate pocketed coins
    accumulatedPocketedCoinsForRound = [...accumulatedPocketedCoinsForRound, ...pocketedCoins];

   

    const checkIfAllStopped = async () => {
        const allObjectsStopped =
            coinsRef.current.every(
                (coin) => Math.abs(coin.velocity.x) === 0 && Math.abs(coin.velocity.y) === 0
            ) &&
            Math.abs(strikerVelocityRef.current.x) === 0 &&
            Math.abs(strikerVelocityRef.current.y) === 0;

        if (allObjectsStopped && !isProcessingPocketedCoins) {
            isProcessingPocketedCoins = true; // Set flag to prevent reprocessing
            setTimeout(async () => {
                console.log('show many coins poketed during the turn',accumulatedPocketedCoinsForRound.length)
                // Process all accumulated pocketed coins
               
                await processPocketedCoins(accumulatedPocketedCoinsForRound, game);
                console.log("Checking queen logic...");
                await checkQueenLogic(game, pocketedCoins, game.turn);
                
            
              
                

                // Clear accumulator and reset flags
                accumulatedPocketedCoinsForRound = [];
                isProcessingPocketedCoins = false;

               

                isClientReadyRef.current = true; 
                console.log('ZZZZZZZZZZZZZZZZZZZZZZZ1111111111111111')
                setSyncGameOnReady((prev) => !prev);
                
               
            }, 20); // Small delay to ensure stability
           
            return;
        } else {
            currentAnimationFrame = requestAnimationFrame(checkIfAllStopped);
            console.log('HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH',allObjectsStopped, isProcessingPocketedCoins)
        }
    };

    // Stop the previous loop if it exists
    if (currentAnimationFrame) {
        cancelAnimationFrame(currentAnimationFrame);
    }

    // Start the new loop
    checkIfAllStopped();

   
};


const lastPositionsRef = useRef(new Map());

const processPocketedCoins = async (pocketedCoins, game) => {
    if (!game) {
        return;
    }
    console.log("Processing pocketed coins:", pocketedCoins);
    console.log("Game state at start:", game);
    let animations = [];

    const strikerFell = isFallingRef.current;

    // Determine current and opponent player IDs
    const currentPlayerId = game.turn; // Player who made the move
    const opponentId = game.userIds.find((id) => id !== currentPlayerId);
    

    const coinsToReturn = [];
    const remainingCoins = [];
    let penalizedCoins = 0;
    const alreadyProcessed = new Set();
    let queenPocketed = false;

    // Precompute counts for pocketed coins
    const currentPlayerPocketed = game.pocketedPieces[currentPlayerId]?.filter(
        (c) => c.type === game.playerColors[currentPlayerId]
    ).length || 0;

    const opponentPocketed = game.pocketedPieces[opponentId]?.filter(
        (c) => c.type === game.playerColors[opponentId]
    ).length || 0;

    const currentTurnPlayerCoins = pocketedCoins.filter(
        ({ coin }) => coin.type === game.playerColors[currentPlayerId]
    ).length;

    const currentTurnOpponentCoins = pocketedCoins.filter(
        ({ coin }) => coin.type === game.playerColors[opponentId]
    ).length;

    const playerCoinsOnBoard = 9 - currentPlayerPocketed - currentTurnPlayerCoins;
    const opponentCoinsOnBoard = 9 - opponentPocketed - currentTurnOpponentCoins;



    pocketedCoins.forEach(({ coin, hole }) => {
        if (alreadyProcessed.has(coin.id)) {
            console.log(`Coin ${coin.id} already processed, skipping.`);
            return; // Skip already processed coins
        }

        console.log(`Processing coin: ${coin.id}, Type: ${coin.type}`);

        if (coin.type === "queen") {
            if (!strikerFell) {
                queenPocketed = true; // Mark that the queen was pocketed
                remainingCoins.push({ coin, hole });
                console.log(`Queen pocketed by player ${currentPlayerId}`);
                
            } else {
                coin.isProcessed = false;
                coinsToReturn.push(coin);
                console.log("Striker fell. Returning queen to center.");
            }
            alreadyProcessed.add(coin.id);
            return;
        }

        if (coin.type === game.playerColors[currentPlayerId]) {
            if ((playerCoinsOnBoard === 0 && game.queenState === "onBoard") || strikerFell) {
                coinsToReturn.push(coin); // Return the player's last coin to the center
                coin.isProcessed = false;
                console.log(`Returning player coin ${coin.id} to center.`);
            } else {
                // Add the player's coin to remaining coins for animation
                remainingCoins.push({ coin, hole });
                console.log(`Adding player coin ${coin.id} to remaining coins.`);
            }
        } else {
            // Opponent's coin
            if (opponentCoinsOnBoard === 0) {
                // Return the opponent's last coin to the center
                coinsToReturn.push(coin);
                coin.isProcessed = false;
                console.log(`Returning opponent coin ${coin.id} to center.`);

                // Apply penalty: Return one of the player's pocketed coins
                penalizedCoins++;
                console.log(`Penalty applied to player ${currentPlayerId}.`);
            } else {
                // Add the opponent's coin to remaining coins for animation
                remainingCoins.push({ coin, hole });
                console.log(`Adding opponent coin ${coin.id} to remaining coins.`);
            }
        }
        alreadyProcessed.add(coin.id);
    });

    // Return coins if the striker fell
    if (coinsToReturn.length > 0) {
        console.log("Returning coins to center:", coinsToReturn.map((c) => c.id));
        animations.push(returnCoinsToCenter(coinsToReturn, game));
    }

  
       
    

     // Return invalid pocketed coins to the board
    if (penalizedCoins > 0) {
    animations.push(applyPenalty(game, game.turn));
    };

    if(strikerFell){
    animations.push(applyPenalty(game, game.turn, true));
    }


    // Animate and finalize remaining coins
    remainingCoins.forEach(({ coin, hole }) => {
        const destination = determineDestination(coin, game);
      
        animations.push(
        triggerCoinPocketAnimation(game, coin, hole, destination, () => {
            const ownerId =
                coin.type === "queen" || coin.type === game.playerColors[currentPlayerId]
                    ? currentPlayerId
                    : opponentId;

            
                // Update pocketedPieces
                game.pocketedPieces[ownerId] = [
                    ...(game.pocketedPieces[ownerId] || []),
                    coin,
                ];

                 // Remove the coin from the board state
                coinsRef.current = coinsRef.current.filter((c) => c.id !== coin.id);
                setCoins([...coinsRef.current]);

                game.strikerBeenHit = true;
                setRenderTrigger((prev) => !prev);
           
        })

        );
    });


    // Wait for all animations to complete
    await Promise.all(animations);

};


const checkQueenLogic = async (game, pocketedCoins, currentPlayerId) => {
    if (!game){
        return;
    }
 

    const animations = [];

    
    const queenOnBoard = game.boardState.coins.find(coin => coin.type === 'queen');
    let playerWithQueen = null;

    
    
    game.userIds.forEach(player => {
        const queenFound = game.pocketedPieces[player]?.find(coin => coin.type === "queen");
        if (queenFound) {
            playerWithQueen = player;
        }
    });

    if (game.queenState === "onBoard" && !queenOnBoard) { 
        if (playerWithQueen) {
            // Animation to return queen to the board from pocketed pieces
            animations.push(returnQueenToBoard(game, playerWithQueen));
        }
    }
    
    
    const strikerFell = isFallingRef.current;
   
    if (game.queenState === "onBoard" && pocketedCoins.some(({ coin }) => coin.type === "queen")) {
        // Queen is pocketed for the first time
        const securedImmediately = pocketedCoins.some(
            ({ coin }) => coin.type === game.playerColors[currentPlayerId]
        );

        if (securedImmediately && !strikerFell) {
            console.log("Queen secured immediately by player!");
            game.queenState = "secured";
            
            
        } else {
            if(!strikerFell){
                console.log("Queen pocketed! Waiting for confirmation...");
                game.queenState = "pocketed";
                game.queenPocketedBy = currentPlayerId;
               
            }
           
        }
       
    } else if (
        game.queenState === "pocketed" &&
        game.queenPocketedBy === currentPlayerId
    ) {
        // Check if the player secures the queen by pocketing their own coin in the next turn
        const secured = pocketedCoins.some(
            ({ coin }) => coin.type === game.playerColors[currentPlayerId]
        );

        if (secured && !strikerFell) {
            console.log("Queen secured by player!");
            game.queenState = "secured";
            
            
           
        } else if (!secured || strikerFell) {
            console.log("Queen not secured. Returning queen to the board.");
            
            
            if (playerWithQueen) {
                // Animation to return queen to the board from pocketed pieces
                animations.push(returnQueenToBoard(game, playerWithQueen));
            }

        } 
    }
    await Promise.all(animations);
   

};




const returnQueenToBoard = (game, queenPocketedPlayer) => {
    return new Promise((resolve) => {
        
    console.log('game object in queens return', game);
    const queenOwnerId = queenPocketedPlayer ;
    console.log('QUEEEEEEEEEEEEEEEEEEEEEEEN OWNERRRRRRRRRRRRRRR',queenOwnerId);
    const queen = game.pocketedPieces[queenOwnerId]?.find((coin) => coin.type === "queen");
    const LastKnownPosition = lastPositionsRef.current.get(queen.type) || { x: 400, y: 150 };



    if (queen) {
        // Add the queen back to the board
        coinsRef.current.push(queen);

        if (queenOwnerId){
            game.pocketedPieces[queenOwnerId] = game.pocketedPieces[queenOwnerId].filter(
                (coin) => coin.id !== queen.id
            );
        } else {
            resolve();
        }
       
    
        

        queen.x = LastKnownPosition.x;
        queen.y = LastKnownPosition.y;

        lastPositionsRef.current.delete(queen.type);

        // Reset queen state and position
        queen.velocity = { x: 0, y: 0 };
        queen.isProcessed = false;
        
        // Trigger UI update
        setCoins([...coinsRef.current]);
        setRenderTrigger((prev) => !prev);

        
        

        // Animate the queen back to the center
        setTimeout(() => {
            returnCoinsToCenter([queen], game);
        }, 300); // Small delay to allow rendering
    } else {
        console.log("Queen not found in pocketedPieces.");
        resolve(); 
    }

    // Reset queen state
    game.queenState = "onBoard";
    game.queenPocketedBy = null;
   
    setTimeout(() => {
        console.log("Queen animation done.");
        resolve(); // Resolve after the animation is complete
    }, 3300); // Example duration for animation
});
};




const determineDestination = (coin, game) => {
    const isPlayerCoin = coin.type === game.playerColors[user._id];
    console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBB',isPlayerCoin)
    const isQueen = coin.type === "queen";


    if (isQueen) {
        // Determine destination based on who pocketed the queen
        if (game.turn === user._id) {
            return getPlayerDestination(coin, game); // Player's side
        } else {
            return getOpponentDestination(coin, game); // Opponent's side
        }
    }


    if (isPlayerCoin) {
        return getPlayerDestination(coin, game); // Player's area for player's coins or queen
    } else {
        console.log('xuyaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaak')
        return getOpponentDestination(coin, game); // Opponent's area for opponent's coins
        
    }
};

const getPlayerDestination = (coin, game) => {
    const queenPocketed = game.pocketedPieces[user._id]?.some(
        (coin) => coin.type === "queen"
    );

    // Player's section is always the bottom part of the screen
    const playerCoinsContainer = document.querySelector(".carrom-player-coins");
    if (!playerCoinsContainer) {
        console.warn("Player coins container not found!");
        return null;
    }

    if (coin.type === "queen" || !queenPocketed) {
        return playerCoinsContainer.querySelector(".carrom-coin-icon").getBoundingClientRect();
    } else {
        return playerCoinsContainer.querySelector(".carrom-coin-icon:nth-child(2)").getBoundingClientRect();
    }
};

const getOpponentDestination = (coin, game) => {
    // Opponent's section is always the top part of the screen
    const opponentId = game.userIds.find((id) => id !== user._id);
    console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',opponentId)


    const queenPocketed = game.pocketedPieces[opponentId]?.some(
        (coin) => coin.type === "queen"
    );

    const opponentCoinsContainer = document.querySelector(".carrom-opponent-coins");
    if (!opponentCoinsContainer) {
        console.log("Opponent coins container not found!");
        return null;
    }

    if (coin.type === "queen" || !queenPocketed) {
        return opponentCoinsContainer.querySelector(".carrom-opponent-coin-icon").getBoundingClientRect();
    } else {
        return opponentCoinsContainer.querySelector(".carrom-opponent-coin-icon:nth-child(2)").getBoundingClientRect();
    }
};



    
    

  
    
    
    
    
    
   

const quantize = (value, precision = 6) => Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);

const checkStrikerCoinCollision = (newX, newY , collisionTracker) => {
    const updatedCoins = coinsRef.current.map((coin) => {
        if (coin.isProcessed) return coin; // Skip pocketed coins

        const dx = coin.x - newX;
        const dy = coin.y - newY;
        const distance = Math.sqrt(dx * dx + dy * dy);

        const collisionKey = `striker-coin${coin.id}`;
        const now = performance.now();

        if (distance < STRIKER_RADIUS + COIN_RADIUS) {
            // Check collision cooldown
            if (collisionTracker.has(collisionKey)) {
                const lastCollisionTime = collisionTracker.get(collisionKey);
                if (now - lastCollisionTime < collisionCooldownMs &&
                    distance > STRIKER_RADIUS + COIN_RADIUS
                ) {
                    console.log("SKIPPINGGGGGGGG striker-coin collision:", collisionKey ,collisionTracker.get(collisionKey));
                    return coin; // Skip collision resolution
                }
            }

            // Update the collision tracker
            collisionTracker.set(collisionKey, now);

            console.log("Resolving striker-coin collision:", collisionKey ,collisionTracker.get(collisionKey));

            // Resolve overlap
            const overlap = STRIKER_RADIUS + COIN_RADIUS - distance;
            const angle = Math.atan2(dy, dx);
            const normal = { x: Math.cos(angle), y: Math.sin(angle) };

            const strikerMass = 2; // Assume striker is heavier
            const coinMass = 1;
            const totalMass = strikerMass + coinMass;

            coin.x += normal.x * (overlap * (strikerMass / totalMass));
            coin.y += normal.y * (overlap * (strikerMass / totalMass));
            newX -= normal.x * (overlap * (coinMass / totalMass));
            newY -= normal.y * (overlap * (coinMass / totalMass));

            // Quantize positions to ensure consistency
            coin.x = quantize(coin.x);
            coin.y = quantize(coin.y);
            newX = quantize(newX);
            newY = quantize(newY);

            // Exchange velocities
            const tangent = { x: -normal.y, y: normal.x };
            const strikerNormalSpeed =
                strikerVelocityRef.current.x * normal.x +
                strikerVelocityRef.current.y * normal.y;
            const strikerTangentSpeed =
                strikerVelocityRef.current.x * tangent.x +
                strikerVelocityRef.current.y * tangent.y;

            const coinNormalSpeed = coin.velocity.x * normal.x + coin.velocity.y * normal.y;
            const coinTangentSpeed = coin.velocity.x * tangent.x + coin.velocity.y * tangent.y;

            const energyLossFactor = 0.8; // Energy retention
            const newStrikerNormalSpeed = coinNormalSpeed * energyLossFactor;
            const newCoinNormalSpeed = strikerNormalSpeed * energyLossFactor;

            const newStrikerVelocity = {
                x: newStrikerNormalSpeed * normal.x + strikerTangentSpeed * tangent.x,
                y: newStrikerNormalSpeed * normal.y + strikerTangentSpeed * tangent.y,
            };
            const newCoinVelocity = {
                x: newCoinNormalSpeed * normal.x + coinTangentSpeed * tangent.x,
                y: newCoinNormalSpeed * normal.y + coinTangentSpeed * tangent.y,
            };

            // Apply minimum separation velocity to avoid sticking
            const minSeparationVelocity = 0.2;
            if (
                Math.abs(newStrikerVelocity.x - newCoinVelocity.x) < minSeparationVelocity &&
                Math.abs(newStrikerVelocity.y - newCoinVelocity.y) < minSeparationVelocity
            ) {
                const adjustment = 0.3; // Small angle adjustment to separate
                newStrikerVelocity.x += adjustment * tangent.x;
                newStrikerVelocity.y += adjustment * tangent.y;
                newCoinVelocity.x -= adjustment * tangent.x;
                newCoinVelocity.y -= adjustment * tangent.y;
            }

            // Quantize velocities to ensure consistency
            strikerVelocityRef.current = {
                x: quantize(newStrikerVelocity.x),
                y: quantize(newStrikerVelocity.y),
            };
            coin.velocity = {
                x: quantize(newCoinVelocity.x),
                y: quantize(newCoinVelocity.y),
            };
        } 

        return coin;
    });

    // Sync updated coins back to the ref
    coinsRef.current = updatedCoins;

    // Trigger UI update
    setCoins([...updatedCoins]);
};



    
    
    
    
    
    
    useEffect(() => {
        if (strikerVelocity.x !== 0 || strikerVelocity.y !== 0) {
            strikerVelocityRef.current = strikerVelocity;
        }
    }, [strikerVelocity]);
    

    
    
    const resolveCoinCollisions = (coinCollisionTracker) => {
        const updatedCoins = [...coinsRef.current];
        const quantize = (value, precision = 6) => Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
        
    
        // Sort coins by ID to ensure deterministic order
        updatedCoins.sort((a, b) => a.id - b.id);
    
        for (let i = 0; i < updatedCoins.length; i++) {
            const coinA = updatedCoins[i];
            if (coinA.isProcessed) continue; // Skip coins already processed (in the hole)
    
            for (let j = i + 1; j < updatedCoins.length; j++) {
                const coinB = updatedCoins[j];
                if (coinB.isProcessed) continue; // Skip coins already processed (in the hole)
    
                 // Create a normalized collision key
            const collisionKey = `coin${Math.min(coinA.id, coinB.id)}-coin${Math.max(coinA.id, coinB.id)}`;
           
            const now = Date.now();

            const dx = coinB.x - coinA.x;
            const dy = coinB.y - coinA.y;
            const distance = Math.sqrt(dx * dx + dy * dy);

            // Check collision cooldown
            if (coinCollisionTracker.has(collisionKey)) {
                const lastCollisionTime = coinCollisionTracker.get(collisionKey);
                if (now - lastCollisionTime < coinCollisionCooldownMs && 
                    distance >= COIN_RADIUS * 2
                ) {
                    continue; // Skip collision resolution
                }
            }


    
               
    
                if (distance < COIN_RADIUS * 2) {
                      // Check if at least one of the coins is moving
                const isCoinAMoving = Math.abs(coinA.velocity.x) > minVelocityThreshold || Math.abs(coinA.velocity.y) > minVelocityThreshold;
                const isCoinBMoving = Math.abs(coinB.velocity.x) > minVelocityThreshold || Math.abs(coinB.velocity.y) > minVelocityThreshold;

                if (!isCoinAMoving && !isCoinBMoving) {
                continue; // Skip resolving the collision if neither coin is moving
                }
                    coinCollisionTracker.set(collisionKey, now);

                    console.log('COOOOOOOOOOOOOOOOOOOOOOOOOOOOOOIN')
    
                    const overlap = COIN_RADIUS * 2 - distance;
    
                    // Resolve overlap naturally
                    const angle = Math.atan2(dy, dx);
                    const normal = { x: Math.cos(angle), y: Math.sin(angle) };
    
                    coinA.x -= normal.x * (overlap / 2);
                    coinA.y -= normal.y * (overlap / 2);
                    coinB.x += normal.x * (overlap / 2);
                    coinB.y += normal.y * (overlap / 2);
    
                    // Quantize positions to ensure consistency
                    coinA.x = quantize(coinA.x);
                    coinA.y = quantize(coinA.y);
                    coinB.x = quantize(coinB.x);
                    coinB.y = quantize(coinB.y);
    
                    // Calculate normal and tangent velocities
                    const tangent = { x: -normal.y, y: normal.x };
                    const vA_normal = coinA.velocity.x * normal.x + coinA.velocity.y * normal.y;
                    const vA_tangent = coinA.velocity.x * tangent.x + coinA.velocity.y * tangent.y;
    
                    const vB_normal = coinB.velocity.x * normal.x + coinB.velocity.y * normal.y;
                    const vB_tangent = coinB.velocity.x * tangent.x + coinB.velocity.y * tangent.y;
    
                    // Elastic collision formula (assuming equal mass)
                    const energyLossFactor = 0.98; // 98% energy retained
                    const vA_normal_final = vB_normal * energyLossFactor;
                    const vB_normal_final = vA_normal * energyLossFactor;
    
                    // Reconstruct velocities after collision
                    const newVelocityA = {
                        x: vA_normal_final * normal.x + vA_tangent * tangent.x,
                        y: vA_normal_final * normal.y + vA_tangent * tangent.y,
                    };
                    const newVelocityB = {
                        x: vB_normal_final * normal.x + vB_tangent * tangent.x,
                        y: vB_normal_final * normal.y + vB_tangent * tangent.y,
                    };
    
                    // Avoid sticking: Slight angular adjustment for near-parallel velocities
                    const angularThreshold = 0.05;
                    const velocitySimilarity =
                        Math.abs(newVelocityA.x - newVelocityB.x) +
                        Math.abs(newVelocityA.y - newVelocityB.y);

                    
                    const bothMoving =
                    (Math.abs(newVelocityA.x) > minVelocityThreshold || Math.abs(newVelocityA.y) > minVelocityThreshold) &&
                    (Math.abs(newVelocityB.x) > minVelocityThreshold || Math.abs(newVelocityB.y) > minVelocityThreshold);
    
                    if (velocitySimilarity < angularThreshold && bothMoving) {
                        const angularAdjustment = 0.1; // Small angle to separate
                        newVelocityA.x += angularAdjustment * tangent.x;
                        newVelocityA.y += angularAdjustment * tangent.y;
                        newVelocityB.x -= angularAdjustment * tangent.x;
                        newVelocityB.y -= angularAdjustment * tangent.y;
                    }
    
                    // Quantize velocities to ensure consistency
                    coinA.velocity = {
                        x: quantize(newVelocityA.x),
                        y: quantize(newVelocityA.y),
                    };
                    coinB.velocity = {
                        x: quantize(newVelocityB.x),
                        y: quantize(newVelocityB.y),
                    };
                }
            }
        }
    
        // Sync the updated coins back to the ref
        coinsRef.current = updatedCoins;
    
        // Trigger UI update (but ensure isProcessed coins are not removed)
        setCoins([...updatedCoins]);
    };
    
    
    
    

    
    





const handleStrikerFallIntoHole = (hole) => {
    console.log("Striker is falling into hole:", hole);

    isFallingRef.current = true;
    strikerVelocityRef.current = { x: 0, y: 0 };

    setStrikerPosition({
        x: hole.x,
        y: hole.y,
    });

    setIsFalling(true);
    audioManager.playSound("fall-into-hole");
   
};





const applyPenalty = (game, currentPlayerId, isStrikerFellToHole = false) => {
    return new Promise((resolve) => {

if (isStrikerFellToHole){
    setIsFoulPopupVisible(true);

} else {
    setIsLastCoinPopupVisible(true);
}



    const penaltyCoins = []; // Coins to be penalized

    if (game.pocketedPieces[currentPlayerId]?.length > 0) {
        // Filter out the queen from potential penalty coins
        const nonQueenCoins = game.pocketedPieces[currentPlayerId].filter(
            (coin) => coin.type !== "queen"
        );

        if (nonQueenCoins.length > 0) {
            // Pop the last non-queen coin for penalty
            const penalizedCoin = nonQueenCoins.pop();

            // Update the player's pocketed pieces to remove the penalized coin
            game.pocketedPieces[currentPlayerId] = game.pocketedPieces[currentPlayerId].filter(
                (coin) => coin.id !== penalizedCoin.id
            );
            if (penalizedCoin) {
                const LastKnownPosition = lastPositionsRef.current.get(penalizedCoin.type) || { x: 400, y: 150 };
              
               
           
                penalizedCoin.x = LastKnownPosition.x;
                penalizedCoin.y = LastKnownPosition.y;


                
                console.log('GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG',lastPositionsRef.current)
    
            
                penalizedCoin.velocity = { x: 0, y: 0 };
                penalizedCoin.isProcessed = false;

                
            
                coinsRef.current.push(penalizedCoin);
             

                setCoins([...coinsRef.current]);
                setRenderTrigger((prev) => !prev);
                
                
              
                penaltyCoins.push(penalizedCoin);
                
            }
            
        }
    }

    // Return penalty coins to the board
    if (penaltyCoins.length > 0) {
        console.log("Penalty coins:", penaltyCoins);
        setTimeout(() => {
           setIsFoulPopupVisible(false);
           setIsLastCoinPopupVisible(false);
           returnCoinsToCenter(penaltyCoins, game);          
        }, 2500);
    } else {
        setTimeout(() => {
            setIsFoulPopupVisible(false);
            setIsLastCoinPopupVisible(false);         
        }, 2500); 
    }
    setTimeout(() => {
        console.log("Coins returned to center.");
        resolve(); // Resolve after the animation is complete
    }, 3500); // Example duration for animation
});
};


const endTurn = () => {
   
    // Reset the "isPocketedThisTurn" flag for the next turn
    coinsRef.current.forEach((coin) => {
        coin.isPocketedThisTurn = false;
    });

   

};



const returnCoinsToCenter = (coins, game) => {
    return new Promise((resolve) => {
    const centerX = 150;
    const centerY = 150;
    const safeDistance = COIN_RADIUS * 2 + 1; // Minimum distance to avoid overlaps

    console.log("Starting returnCoinsToCenter...");
    console.log("Initial coins positions:", coins.map((c) => ({ id: c.id, x: c.x, y: c.y })));

    let angleOffset = 0; // Initialize angle for circular placement
    

    const quantize = (value, precision = 6) =>
        Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);

    // Sort coins by ID to ensure consistent order
    coins.sort((a, b) => a.id - b.id);

    const findClearPosition = (targetX, targetY, isFirstCoin = false) => {
        if (isFirstCoin) {
            const overlaps = [...coinsRef.current.map((coin) => ({ x: coin.x, y: coin.y })), ...assignedPositions.current].some(
                (pos) => {
                    const dx = pos.x - targetX;
                    const dy = pos.y - targetY;
                    return Math.sqrt(dx * dx + dy * dy) < safeDistance;
                }
            );
            if (!overlaps) {
                const quantizedPosition = {
                    x: quantize(targetX),
                    y: quantize(targetY),
                };
                assignedPositions.current.push(quantizedPosition);
                return quantizedPosition;
            }
        }

        let radius = safeDistance; // Start with a small radius
        let angle = angleOffset; // Use an incremental angle for predictable placement
        let x, y;
        let attempts = 0;

        while (attempts < 1500) {
            x = targetX + Math.cos(angle) * radius;
            y = targetY + Math.sin(angle) * radius;

            const overlaps = [...coinsRef.current.map((coin) => ({ x: coin.x, y: coin.y })), ...assignedPositions.current].some(
                (pos) => {
                    const dx = pos.x - x;
                    const dy = pos.y - y;
                    return Math.sqrt(dx * dx + dy * dy) < safeDistance;
                }
            );

            if (!overlaps) {
                break; // Found a clear position
            }

            // Increment angle and radius slightly for next attempt
            angle += Math.PI / 6; // Increment by 30 degrees
            if (angle >= 2 * Math.PI) {
                angle = 0;
                radius += safeDistance; // Expand radius after a full circle
            }
            attempts++;
        }

        if (attempts >= 1500) {
            console.warn("Max attempts reached in findClearPosition. Using fallback position.");
        }

        const quantizedPosition = {
            x: quantize(x),
            y: quantize(y),
        };
        assignedPositions.current.push(quantizedPosition);
        angleOffset += Math.PI / 6; // Update global angle offset for the next coin
        return quantizedPosition;
    };

    // Precompute destinations for all coins
    const destinations = coins.map((coin, index) =>
        findClearPosition(centerX, centerY, index === 0) // Check if it's the first coin
    );
    console.log("Computed destinations:", destinations);

       // Determine current and opponent player IDs
    const currentPlayerId = game.turn; // Player who made the move
    const opponentId = game.userIds.find((id) => id !== currentPlayerId);

    requestAnimationFrame(() => {
        coins.forEach((coin, index) => {
            const destination = destinations[index];

            console.log("Destination for coin:", destination);
            // Only adjust for rotation when animating/rendering
            const adjustedDestination = adjustForRotation(destination, game);
            console.log("Adjusted destination for coin:", adjustedDestination);

            // Trigger animation to the adjusted destination
           
            triggerCoinReturnAnimation(game, coin, adjustedDestination, () => {
                console.log(`Coin ${coin.id} animation completed.`);
                game.strikerBeenHit = true;
                
            });
    
            

            // Update the coin's actual state with the unrotated position
            coin.x = destination.x;
            coin.y = destination.y;
            coin.velocity = { x: 0, y: 0 };
            coin.isProcessed = false;
           

            
        });

        setCoins([...coinsRef.current]); // Sync state
        setRenderTrigger((prev) => !prev);
    });
    setTimeout(() => {
        console.log("Penalty applied.");
        resolve(); // Resolve when penalty application is done
    }, 3000);
});
   
};







    
const triggerCoinReturnAnimation = (game, coin, destination, onComplete) => {


    const coinElement = document.querySelector(`#coin-${coin.id}`); 
    if (!coinElement) {
        console.log('Coin element not found for coin ID:');

        if (onComplete) onComplete(); // Call the callback to avoid blocking readiness
        
        return;
    }


    // Apply initial position (always ensure this is set)
    coinElement.style.position = "absolute";


    // Set transition for smooth animation
    coinElement.style.transform = "translate(-50%, -50%) scale(1)";
    coinElement.style.transition = "top 0.6s ease, left 0.6s ease";

    // Animate to the destination
    requestAnimationFrame(() => {
        coinElement.style.left = `${destination.x}px`;
        coinElement.style.top = `${destination.y}px`;
    });

    // Handle completion of the animation
    coinElement.addEventListener(
        "transitionend",
        () => {
         
            coinElement.style.transition = ""; // Clean up transition styles
            if (onComplete) onComplete();
        },
        { once: true }
    );
};




        

useEffect(() => {
    if (!game) return ;
    const handleMouseMove = (event) => {
        if (isDragging) handleDragMove(event, game);
        if (isTouchingStriker) handleStrikerTouchMove(event, game);
    };

    const handleMouseUp = () => {
        if (isDragging) handleDragEnd();
        if (isTouchingStriker) handleStrikerTouchEnd(game);
    };

    const preventDefaultTouch = (event) => {
        event.preventDefault(); // Prevent default behavior (e.g., page scroll, Telegram minimize)
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("touchmove", handleMouseMove, { passive: false });
    window.addEventListener("touchend", handleMouseUp, { passive: false });
    window.addEventListener("touchstart", preventDefaultTouch, { passive: false });

    return () => {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
        window.removeEventListener("touchmove", handleMouseMove);
        window.removeEventListener("touchend", handleMouseUp);
        window.removeEventListener("touchstart", preventDefaultTouch);
    };
}, [isDragging, isTouchingStriker, game]);



    

        

    
  
    // Function to adjust coordinates for second player's perspective
    const adjustForRotation = (position, currentGame = game) => {
        const isSecondPlayer = currentGame?.playerColors[user._id] === "black";

        if (!isSecondPlayer) return position;
        return {
            x: 300 - position.x, // Reverse X coordinate (300 is the board size)
            y: 300 - position.y, // Reverse Y coordinate
        };
    };

  
 
    



const renderPlayArea = () => {
    if (!game) return;

    



    const PlayerTurn = game.turn === user._id;
    



    // Adjust striker position for second player's perspective
    const adjustedStrikerPosition = adjustForRotation(strikerPosition);

    return (
        <div className="carrom-board-wrapper">
            <div className="carrom-board-container">
                <div className="carrom-board-border">
                    <div className="carrom-board">
                        <div className="board-lines"></div>
                        
                        
                        {isFoulPopupVisible && (
                        <div className="striker-foul-popup">
                        <div className="striker-popup-content">
                        <div className="striker-popup-header">Foul!</div>
                        <div className="striker-popup-message">
                        Striker fell into the hole. Penalty applied.
                        </div>
                        </div>
                        </div>
                        )}

                        {isLastCoinPopupVisible && !game.strikerFell && (
                        <div className="striker-foul-popup">
                        <div className="striker-popup-content">
                        <div className="striker-popup-header">Foul!</div>
                        <div className="striker-popup-message">
                        Last opponent coin poketed. Penalty applied.
                        </div>
                        </div>
                        </div>
                        )}

                        {renderGameEndPopup()}


                        {/* Render coins */}
                        {coinsRef.current.map((coin) => {
                            const highlightQueen =
                                coin.type === "queen" &&
                                game.turn === user._id &&
                                game.queenState === "onBoard" &&
                                (game.pocketedPieces[user._id]?.length || 0) === 8;

                            const adjustedPosition = adjustForRotation({ x: coin.x, y: coin.y });

                            return (
                                <img
                                    key={coin.id}
                                    id={`coin-${coin.id}`}
                                    src={`/carromAssets/${coin.type}-coin.png`}
                                    alt={`${coin.type} coin`}
                                    className={`carrom-coin ${highlightQueen ? "highlight-queen" : ""}`}
                                    style={{
                                        top: `${adjustedPosition.y}px`,
                                        left: `${adjustedPosition.x}px`,
                                        transform: "translate(-50%, -50%)",
                                    }}
                                />
                            );
                        })}

                        {/* Render striker animation */}
                        {PlayerTurn && isControllerActive && !isTransitioning && !isFalling && (
                            <div
                            className={`carrom-striker-animation ${
                                isDragging ? "carrom-small" : ""
                            } ${isOverlapping ? "carrom-overlap" : ""}`}
                            style={{
                                top: `${adjustedStrikerPosition.y}px`,
                                left: `${adjustedStrikerPosition.x}px`,
                            }}
                            >
                                <div className="carrom-arrow carrom-arrow-top"></div>
                                <div className="carrom-arrow carrom-arrow-bottom"></div>
                                <div className="carrom-arrow carrom-arrow-left"></div>
                                <div className="carrom-arrow carrom-arrow-right"></div>
                            </div>
                        )}

                        {/* Render striker */}
                        <img
                            src="/carromAssets/striker.png"
                            alt="Striker"
                            className={`carrom-striker ${isFalling ? "falling" : ""}`}
                            style={{
                                top: `${adjustedStrikerPosition.y}px`,
                                left: `${adjustedStrikerPosition.x}px`,
                                transform: "translate(-50%, -50%)",
                            }}
                            onMouseDown={(event) => handleStrikerTouchStart(event, game)}
                            onTouchStart={(event) => handleStrikerTouchStart(event, game)}
                        />

                        {/* Hit Circle */}
                        {isTouchingStriker && !timerExecuted && (
                            <div
                                className="carrom-hit-circle"
                                style={{
                                    top: `${adjustedStrikerPosition.y}px`,
                                    left: `${adjustedStrikerPosition.x}px`,
                                    width: `${22 * circleScale}px`,
                                    height: `${22 * circleScale}px`,
                                    transform: "translate(-50%, -50%)",
                                }}
                            ></div>
                        )}

                        {/* Hit Arrow */}
                        {isTouchingStriker && !timerExecuted && (
                            <div
                                className="carrom-hit-arrow"
                                style={{
                                    top: `${adjustedStrikerPosition.y}px`,
                                    left: `${adjustedStrikerPosition.x}px`,
                                    width: `${hitArrow.length}px`,
                                    height: `4px`,
                                    backgroundColor: "gold",
                                    transform: `translate(0, -50%) rotate(${
                                        game?.playerColors[user._id] === "black" ? (hitArrow.angle + 180) % 360 : hitArrow.angle
                                    }deg)`,
                                    transformOrigin: "0% 50%",
                                }}
                            ></div>
                        )}

                        {/* Render White Dots */}
                        {!timerExecuted && isTouchingStriker &&
                            whiteDots.map((dot, index) => {
                                const adjustedDot = adjustForRotation(dot);
                                return (
                                    <div
                                        key={`carrom-white-dot-${index}`}
                                        className="white-dot"
                                        style={{
                                            position: "absolute",
                                            width: "8px",
                                            height: "8px",
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                            top: `${adjustedDot.y}px`,
                                            left: `${adjustedDot.x}px`,
                                            opacity: dot.opacity,
                                            transform: "translate(-50%, -50%)",
                                        }}
                                    ></div>
                                );
                            })}

                        {/* Render Drag Dots */}
                        {!timerExecuted && 
                            dragDots.map((dot, index) => {
                            const adjustedDot = adjustForRotation(dot);
                            return (
                                <div
                                    key={`dot-${index}`}
                                    className="drag-dot"
                                    style={{
                                        position: "absolute",
                                        width: "6px",
                                        height: "6px",
                                        backgroundColor: "black",
                                        borderRadius: "50%",
                                        top: `${adjustedDot.y}px`,
                                        left: `${adjustedDot.x}px`,
                                        transform: "translate(-50%, -50%)",
                                    }}
                                ></div>
                            );
                        })}
                    </div>
                </div>
            </div>

            {/* Striker Controller */}
            <div
                className={`striker-controller ${PlayerTurn && isControllerActive ? "active" : "inactive"}`}
            >
                <div
                    className="striker-handle"
                    onMouseDown={handleDragStart}
                    onTouchStart={handleDragStart}
                    style={{
                        left: `${
                            game?.playerColors[user._id] === "black" // Check if the player is the second player
                                ? controllerWidth - handleWidth - handlePosition // Mirror the position
                                : handlePosition
                        }px`
                    }}
                ></div>
            </div>
        </div>
    );
};


    
    
    
    
    
    


const renderPlayerArea = (game, user) => {
    if (!user) {
        return null; 
    }

    return (
        <div className={`carrom-player-area ${game.turn === user._id && !game.strikerBeenHit  ? 'highlighted waiting-for-move' : ''}`}>
            <div className="carrom-player-avatar">
                {renderAvatar(user.nickname, user.photoUrl)}
                <svg className="Carrom-timer-circleV">
            <defs>
                <linearGradient id="gradientStroke" x1="50%" y1="0%" x2="50%" y2="100%">
                    <stop offset="0%" style={{ stopColor: '#199519', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: '#19E127', stopOpacity: 1 }} />
                </linearGradient>
            </defs>
            <circle className="Carrom-base" cx="50%" cy="50%" r="45%" />
            <circle className="Carrom-timerP" cx="50%" cy="50%" r="45%" stroke="url(#gradientStroke)" />
        </svg>
            </div>

            <div className="carrom-player-info">
                <div className="carrom-name-and-flag">
                    <span className="carrom-player-name">{user.nickname}</span>
                    {user.country && (
                        <img
                            src={`/flags/${user.country.toLowerCase()}.svg`}
                            alt={`${user.country} flag`}
                            className="carrom-country-flag"
                        />
                    )}
                </div>
                <span className="carrom-player-score">Score: {user.score}</span>
                <span className="carrom-player-betAmmount">Bet: ${game?.betAmount}</span>
            </div>

            <div className="carrom-player-coins">
                {game.pocketedPieces[user._id]?.some(coin => coin.type === 'queen') && (
                    <div
                        className="carrom-coin-icon"
                        style={{
                            backgroundImage: `url(/carromAssets/queen-coin.png)`,
                            opacity: game.queenState === "secured" ? 1 : 0.3,
                        }}
                    ></div>
                )}
                <div
                    className="carrom-coin-icon"
                    style={{
                        backgroundImage: `url(/carromAssets/${game.playerColors[user._id]}-coin.png)`,
                    }}
                ></div>
                <div className="carrom-coin-count">
                    {game.pocketedPieces[user._id]?.filter(coin => coin.type === game.playerColors[user._id] && coin.type !== 'queen').length || 0}
                </div>
            </div>
        </div>
    );
};

    


    
    // Render cancel popup
    const renderPopup = () => {
        if (ShowCancelPopup) {
            return (
                <div className="carrom-popup">
                    <div className="carrom-popup-inner">
                        <h3>Game Canceled</h3>
                        <p>The game has been canceled. Your bet has been refunded.</p>
                        <button onClick={() => setShowCancelPopup(false)}>Close</button>
                    </div>
                </div>
            );
        }
    };

    // Main rendering logic
    if (!game) {
        return (
            <div className="carrom-container">
                <h2>Loading game...</h2>
            </div>
        );
    }








    


    
    return (
 
        <div className="carrom-container">

            


            {renderOpponentArea(game, user, opponents)}
            
            {renderPlayArea()}
            


            <div className="carrom-player-wrapper">
            {renderPlayerArea(game, user)}
            </div>


            {renderPopup()}
        
        </div>
    );
};

export default CarromGame;