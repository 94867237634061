import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import { apiRequest } from '../api';
import { useNavigate, useLocation } from 'react-router-dom';
import API_BASE_URL from '../apiConfig';
import { io } from 'socket.io-client';

export const AuthContext = createContext();

const SOCKET_URL = `${API_BASE_URL}`;
const socket = io(SOCKET_URL, {
    transports: ['websocket'],
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 500,
    reconnectionDelayMax: 1000,
    timeout: 60000,
    autoConnect: true,
});

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [balance, setBalance] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    
    const login = useCallback((userData) => {
        setUser(userData);
        setBalance(userData.balance);
    }, []);

    const logout = useCallback(() => {
        setUser(null);
        setBalance(0);
        navigate('/login');
        apiRequest(`${API_BASE_URL}/api/logout`, {
            method: 'POST',
            credentials: 'include',
        });
    }, [navigate]);

    const fetchUserDetails = useCallback(async (retries = 3, delay = 1500) => {
        for (let attempt = 0; attempt < retries; attempt++) {
            try {
                const response = await apiRequest(`${API_BASE_URL}/api/protected`, {
                    method: 'GET',
                    credentials: 'include',
                });
                if (response.ok) {
                    const data = await response.json();
                    setUser(data.user);
                    setBalance(data.user.balance);

                    if (socket && socket.connected) {
                        socket.emit('authenticate', { userId: data.user._id });
                    } else {
                        socket.once('connect', () => {
       
                            socket.emit('authenticate', { userId: data.user._id });
                        });
                    }
                    return;
                } else if (response.status === 401) {
                    console.warn(`Attempt ${attempt + 1} failed with 401 Unauthorized`);
                    if (attempt < retries - 1) {
                        await new Promise(resolve => setTimeout(resolve, delay));
                    } else {
                        logout();
                    }
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
                break;
            }
        }
    }, [logout]);

    useEffect(() => {
        const publicRoutes = ['/register', '/forgot-password', '/login', '/admin/login', '/admin/dashboard', '/admin/completed-games', '/admin/users'];
        
        const isResetPasswordRoute = location.pathname.startsWith('/reset-password');

        if (!publicRoutes.includes(location.pathname) && !isResetPasswordRoute ) {
            fetchUserDetails();
        }
    }, [fetchUserDetails, location.pathname, socket ]);

    useEffect(() => {
        const handleSocketReconnect = () => {
            if (user && socket.connected) {
                socket.emit('authenticate', { userId: user._id });
            }
        };
    
        // Listen for the socket reconnect event and trigger authentication
        socket.on('connect', handleSocketReconnect);
    
        // Cleanup on component unmount or when socket changes
        return () => {
            socket.off('connect', handleSocketReconnect);
        };
    }, [socket, user]); 

    useEffect(() => {
        if (socket) {
            socket.on('balanceUpdated', (newBalance) => {
                setBalance(newBalance);
            });
    
            return () => {
                socket.off('balanceUpdated');
            };
        }
    }, [socket]);

    const updateUserDetails = async () => {
        if (!user || !user._id) {
            console.error('Cannot update user details: User ID is missing.');
            return;
        }

        try {
            const response = await apiRequest(`${API_BASE_URL}/api/user/${user._id}`, {
                method: 'GET',
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                setUser({ 
                    ...user, 
                    nickname: data.username, 
                    balance: data.balance, 
                    score: data.score, 
                    country: data.country 
                });
                setBalance(data.balance);
            } else {
                console.error('Failed to fetch user details:', response.status);
            }
        } catch (error) {
            console.error('Error updating user details:', error.message);
        }
    };
    
  
    

    // Memoize context values to avoid re-renders
    const contextValue = useMemo(() => ({
        user,
        balance,
        setBalance,
        login,
        logout,
        fetchUserDetails,
        updateUserDetails,
        socket 
    }), [user, balance, login, logout, fetchUserDetails, socket]);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider };
