import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { apiRequest } from '../api';
import './RPSGame.css';
import audioManager from './AudioManager'; 

const RPSGame = () => {
    const { socket, user } = useContext(AuthContext);

    // State variables
    const [game, setGame] = useState(null);
    const navigate = useNavigate();
    const [opponents, setOpponents] = useState([]);
    const [isGameEnd, setIsGameEnd] = useState(false);
    const [playerMove, setPlayerMove] = useState(null);
    const [isMoveLoading, setIsMoveLoading] = useState(false);
    const [opponentMove, setOpponentMove] = useState(null);
    const [showMoveButtons, setShowMoveButtons] = useState(false);
    const [currentRound, setCurrentRound] = useState(null);
    const [popupMessage, setPopupMessage] = useState('');
    const [roundResults, setRoundResults] = useState([]);
    const [animateRound, setAnimateRound] = useState(true);
    const [ShowCancelPopup, setShowCancelPopup] = useState(false);
    const [countdown, setCountdown] = useState(15);

    useEffect(() => {
        if (currentRound !== null) {
          
            setAnimateRound(true);
            const timer = setTimeout(() => setAnimateRound(false), 2000); 
            return () => clearTimeout(timer); 
        }
    }, [currentRound]);

   
    

   useEffect(() => {
    let timer;
    if (countdown > 0 && !playerMove) {
        timer = setInterval(() => {
            setCountdown((prev) => prev - 1);
        }, 1000);
    }

    return () => clearInterval(timer);
    }, [countdown, playerMove]);



    const fetchGameState = useCallback(async () => {
        try {
            const response = await apiRequest('/api/current-game', {
                method: 'GET',
                credentials: 'include',
            });
            const data = await response.json();

            if (response.ok && data.game) {
                if (data.game.status === 'completed') {
                    navigate('/game');
                    return; 
                }
            
                
                setGame(data.game);
                setCurrentRound(data.game.currentRound);
                setOpponents(data.opponents || []);
                handleGamePhase(data.game);
            } else {
                setGame(null);
                setPopupMessage('Failed to fetch the current game state.');
                
                return; 
            }
        } catch (error) {
            console.error('Error fetching game state:', error);
        }
    }, [navigate]);

    useEffect(() => {
        if (!user) {
            navigate('/login');
            return;
        }
        socket.on('connect', () => {
            fetchGameState();
        });
        socket.on('game-update', (data) => {
                     
            if (!data.game) {
                navigate('/game');
                return; 
            }


            setGame(data.game);
            setCurrentRound(data.game.currentRound);
            setOpponents(data.opponents || []);
            handleGamePhase(data.game);
        });

        return () => {
            socket.off('game-update');
            socket.off('connect');
        };
    }, [socket, user, navigate]);


    const handleGamePhase = (gameData) => {
        switch (gameData.phase) {
            case 'choose':
                case 'choose':
                setPlayerMove(null);
                setOpponentMove(null);
                setCurrentRound(gameData.currentRound);
                
                
            break;

            case 'waiting-for-opponent':
            const playerChoice = gameData.choices[user._id];
            if (playerChoice) {
                setPlayerMove(playerChoice);
                
            }
            break;


            case 'round-completed': {
                if (gameData.choices && gameData.userIds?.length === 2) {
       
                    const [player1Id, player2Id] = gameData.userIds;
            
                
                    const playerPlayedMove = gameData.choices[user._id]; 
                    const opponentPlayedMove =
                        player1Id === user._id
                            ? gameData.choices[player2Id] 
                            : gameData.choices[player1Id];
           
                    setPlayerMove(playerPlayedMove);
                    setOpponentMove(opponentPlayedMove);
                    setCountdown(15);
                   
                }
            
           
            
                break;
            }
            
            case 'game-canceled': {
                setPlayerMove(null);
                setOpponentMove(null);
                setShowCancelPopup(true); 
                setTimeout(() => {
                    setShowCancelPopup(false);
                    navigate('/game'); 
                }, 4000);
                break;
            }
            

            
            case 'game-completed':
                
                setIsGameEnd(true);
          
                break;
                
            default:
                console.warn(`Unhandled game phase: ${gameData.phase}`);
        }
    };
    


    const handlePlayerChoice = async (choice) => {
        audioManager.playSound('buttonClick');
        setPlayerMove(choice); 
      
        setIsMoveLoading(true);
    
        try {
            const response = await apiRequest('/api/rps-move', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    gameId: game._id,
                    choice: choice,
                }),
            });
    
            if (!response.ok) throw new Error('Failed to make a move.');
           
        } catch (error) {
            console.error('Error sending move:', error);
        }finally {
            setIsMoveLoading(false);
        }
    };

   
    
    
useEffect(() => {
    const handleVisibilityChange = () => {
        if (!document.hidden) {
            fetchGameState();
        }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
}, [fetchGameState]); 
   

    useEffect(() => {
        fetchGameState();
    }, [fetchGameState]);


   
const calculateWinnerGain = (betAmount) => {
    switch (betAmount) {
        case 1:
            return 1.8;
        case 3:
            return 5;
        case 5:
            return 9;
        default:
            return 0; 
    }
};
    

const triggerConfettiAnimation = () => {
    const confettiContainer = document.createElement('div');
    confettiContainer.className = 'confetti-container';

    for (let i = 0; i < 200; i++) { 
        const confetti = document.createElement('div');
        confetti.className = 'confetti-piece';
        
      
        confetti.style.left = '50%';
        confetti.style.top = '50%'; 
        confetti.style.backgroundColor = `hsl(${Math.random() * 360}, 100%, 50%)`; 
        confetti.style.transform = `rotate(${Math.random() * 360}deg)`; 

  
        const randomX = (Math.random() - 0.5) * window.innerWidth * 2; 
        const randomY = (Math.random() - 0.5) * window.innerHeight * 2;
        const duration = Math.random() * 1.4 + 0.8; 

        confetti.style.animation = `explode ${duration}s ease-out forwards`;
        confetti.style.setProperty('--randomX', `${randomX}px`); 
        confetti.style.setProperty('--randomY', `${randomY}px`); 

        confettiContainer.appendChild(confetti);
    }

    document.body.appendChild(confettiContainer);

    setTimeout(() => {
        document.body.removeChild(confettiContainer);
    }, 4000);  
};

useEffect(() => {
    if (game && user && isGameEnd) {
        if (game.winners?.includes(user._id)) {
            triggerConfettiAnimation();
            audioManager.playSound('win'); 
        } else {
            audioManager.playSound('lose'); 
        }

   
        const timeout = setTimeout(() => {
            navigate('/game'); 
        }, 5000);

        return () => clearTimeout(timeout); 
    }
}, [isGameEnd, game, user._id, navigate]);
    

const renderGameEndPopup = () => {
    if (!isGameEnd || !game) return null;

    const winnersArray = game.winners || [];
    const losersArray = game.losers || [];
    const winnerId = winnersArray[0] || null;
    const loserId = losersArray[0] || null;
    const winnerPoints = game.totalGamePoints[winnerId] || 0;
    const loserPoints = game.totalGamePoints[loserId] || 0;
    const isUserWinner = winnerId === user._id;

    return (
        <div className="rps-end-game-popup">
            <div className="rps-end-game-header">
                <h3 className={isUserWinner ? "rps-win" : "rps-lose"}>
                    {isUserWinner ? (
                        <>
                            <span className="rps-end-game-emoji">🏆</span> You Won the Game!
                        </>
                    ) : winnerId ? (
                        <>
                            <span className="rps-end-game-emoji">😞</span> {game.usernames[winnerId]} Won the Game
                        </>
                    ) : (
                        <>
                            <span className="rps-end-game-emoji">🤝</span> The Game is a Tie!
                        </>
                    )}
                </h3>
            </div>

            <div className="rps-end-game-results-table">
                <div className="rps-end-game-results-row rps-end-game-header">
                    <div>Game Result</div>
                </div>
                <div className="rps-end-game-results-row rps-end-game-score">
                    <div className={isUserWinner ? "rps-end-game-winner-score" : "rps-end-game-loser-score"}>
                        {isUserWinner ? winnerPoints : loserPoints}
                    </div>
                    <span className="rps-end-game-colon">:</span>
                    <div className={isUserWinner ? "rps-end-game-loser-score" : "rps-end-game-winner-score"}>
                        {isUserWinner ? loserPoints : winnerPoints}
                    </div>
                </div>

                <div className="rps-results-row rps-score-row">
                    <div className="rps-results-left">
                        <div className="rps-score-icon">
                            <img src="/icons/score-icon.svg" alt="Score" />
                        </div>
                        <div className="rps-current-score">{user.score}</div>
                    </div>
                    <div className={`rps-score-result ${isUserWinner ? 'rps-score-positive' : 'rps-score-negative'}`}>
                        {isUserWinner ? "+10" : "-5"}
                    </div>
                </div>

                <div className="rps-results-row rps-bet-row">
                    <div className="rps-results-left">Bet Amount</div>
                    <div className="rps-results-right">
                        <span className="rps-dollar-signBET">$</span>
                        <span className="rps-bet-amount">{game.betAmount}</span>
                    </div>
                </div>

                {isUserWinner && game.betAmount > 0 && (
                    <div className="rps-results-row rps-winning-row">
                        <div className="rps-results-left">Winnings</div>
                        <div className="rps-results-right">
                            <span className="rps-dollar-sign">$</span>
                            <span className="rps-winning-amount">{calculateWinnerGain(game.betAmount)}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

    


    const renderRoundNumber = () => {


        return (
            <div className={`rps-round-number ${animateRound ? 'rps-animate' : ''}`}>
                Round {currentRound}
            </div>
        );
    };

    const renderAvatar = (name, photoUrl) => {
        if (photoUrl) {
            return <img src={photoUrl} alt={`${name}'s Avatar`} className="rps-avatar-image" />;
        }
        const initial = name.charAt(0).toUpperCase(); 
        return <div className="rps-avatar-placeholder">{initial}</div>;
    };

      

    const renderOpponentArea = (game, user, opponents, currentRound) => {
        const opponent = opponents.find(opponent => opponent._id !== user._id);

    
        if (!opponent) {
            return null; 
        }

        const opponentWins = game.totalGamePoints[opponent._id] || 0;
    

        
        
        
        return (
            <div className="rps-opponent-area">
                {/* Opponent Avatar */}
            <div className="rps-opponent-avatar">
                {renderAvatar(opponent.nickname, opponent.photoUrl)}
            </div>
    
                {/* Opponent Info */}
                <div className="rps-opponent-info">
                    <div className="rps-name-and-flag">
                        <span className="rps-opponent-name">{opponent.nickname}</span>
                        {opponent.country && (
                            <img
                                src={`/flags/${opponent.country.toLowerCase()}.svg`}
                                alt={`${opponent.country} flag`}
                                className="rps-country-flag"
                            />
                        )}
                    </div>
                    <span className="rps-opponent-score">Score: {opponent.score}</span>
                </div>
    
                {/* Win Count Placeholders */}
                <div className="rps-opponentRound-result">
    {Array(3)
        .fill(0)
        .map((_, index) => (
            <div key={index} className="rps-placeholder">
                {index < opponentWins && (
                    <span className="rps-trophy-icon" role="img" aria-label="Trophy">
                        🏆
                    </span>
                )}
                
            </div>
        ))}
</div>
            </div>
        );
    };
    

    const renderPlayerArea = (game, user, currentRound) => {
        if (!user) {
            return null; // If no user data is found, return nothing
        }

        const playerWins = game.totalGamePoints[user._id] || 0;
        
    
        return (
            <div className="rps-player-area">
                {/* Player Avatar */}
            <div className="rps-player-avatar">
                {renderAvatar(user.nickname, user.photoUrl)}
            </div>
    
                {/* Player Info */}
                <div className="rps-player-info">
                    <div className="rps-name-and-flag">
                        <span className="rps-player-name">{user.nickname}</span>
                        {user.country && (
                            <img
                                src={`/flags/${user.country.toLowerCase()}.svg`}
                                alt={`${user.country} flag`}
                                className="rps-country-flag"
                            />
                        )}
                    </div>
                    <span className="rps-player-score">Score: {user.score}</span>
                </div>
    
                <div className="rps-playerRound-result">
    {Array(3)
        .fill(0)
        .map((_, index) => (
            <div key={index} className="rps-placeholder">
                {index < playerWins && (
                    <span className="rps-trophy-icon" role="img" aria-label="Trophy">
                        🏆
                    </span>
                )}
                
            </div>
        ))}
</div>
        </div>
    );
    };

    const renderPopup = () => {
        if (ShowCancelPopup) {
            return (
                <div className="popup">
                    <div className="popup-inner">
                        <h3>Game Canceled</h3>
                        <p>The game has been canceled due to server maintenance. Your bet has been refunded.</p>
                        <button onClick={() => setShowCancelPopup(false)}>Close</button>
                    </div>
                </div>
            );
        }


    };

    const renderPlayButtons = (handlePlayerChoice, playerMove, countdown, game) => {
    
        if (animateRound || isGameEnd) {
            return null; 
        }
        const options = [
            { name: "rock", icon: "/rpsAssets/rock.png" },
            { name: "paper", icon: "/rpsAssets/paper.png" },
            { name: "scissors", icon: "/rpsAssets/scissors.png" },
        ];
       
    
        if (playerMove) {
            
            const currentRoundData = game.roundResults
            .filter((round) => round.roundNumber === game.currentRound)
            .pop();
            const winnerId = currentRoundData?.winnerId;
            
            return (
                <div
                    className={`rps-play-choice ${
                        game.phase === "round-completed" ? "rps-animate-to-play-area" : ""
                    }`}
                >
                    <div className={`rps-chosen-icon-wrapper ${playerMove}`}>
                        <img
                            src={`/rpsAssets/${playerMove}.png`}
                            alt={playerMove}
                            className={`rps-chosen-icon ${
                                game.phase === "round-completed" && winnerId === user._id
                                    ? "rps-winner-icon-delay"
                                    : game.phase === "round-completed" && winnerId === null
                                    ? "rps-tie-icon-delay" 
                                    : game.phase === "round-completed" && winnerId !== user._id && winnerId !== null
                                    ? "rps-no-animation" 
                                    : ""
                            }`}
                        />
                    </div>
                    {game?.phase === "waiting-for-opponent" && (
                        <div className="rps-waiting-message">Waiting for opponent...</div>
                    )}
                </div>
            );
        }
        
        
        
    
        if (game.phase === "choose" || game.phase === "waiting-for-opponent") {
        return (
            <div className="rps-play-buttons-wrapper">
                <div className="rps-countdown">
                    Time left: {countdown > 0 ? `${countdown}s` : "Auto-picking..."}
                </div>
                <div className="rps-play-buttons">
                    {options.map((option) => (
                        <button
                            key={option.name}
                            className="rps-play-button" 
                            onClick={() => handlePlayerChoice(option.name)}
                        >
                            <img src={option.icon} alt={option.name} className="rps-play-icon" />
                        </button>
                    ))}
                </div>
            </div>
        );
    }
    };
    
    
    
   


    

    if (!game) {
        return (
            <div className="rps-container">
                <h2>Loading game...</h2>
            </div>
        );
    }
  
    return (
        <div className="rps-container">

            {renderRoundNumber()}
            <div className="rps-opponent-wrapper">
            {game && renderOpponentArea(game, user, opponents, currentRound)}
            </div>
            

            <div className="rps-play-area">
 
    {opponentMove && game?.phase === "round-completed" && (
        (() => {
            const currentRoundData = game.roundResults
                .filter((round) => round.roundNumber === game.currentRound)
                .pop();

         
            const winnerId = currentRoundData?.winnerId;
            

            return (
                <div className="rps-opponent-move-wrapper">
                    <img
                        src={`/rpsAssets/${opponentMove}.png`}
                        alt={opponentMove}
                        className={`rps-opponent-move-icon ${opponentMove} ${
                            winnerId === null
                            ? "rps-tie-icon-delay" 
                            : winnerId !== user._id
                            ? "rps-winner-icon-delay" 
                            : "rps-no-animation"
                    }`}
                    />
                </div>
            );
        })()
    )}
</div>






            <div className="rps-play-buttons-wrapper">
            {renderPlayButtons(handlePlayerChoice, playerMove, countdown, game)}
            </div>

            
           

            <div className="rps-player-wrapper">
            {renderPlayerArea(game, user, currentRound)}
            </div>

           
            {renderGameEndPopup()}

            {renderPopup()}
        </div>
    );
};

export default RPSGame;
